/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import DiscountIcon from "@mui/icons-material/Discount";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Pagination } from "@mui/material";
import { Chip, iconButtonClasses } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";

const currencySymbolFormatter = (value) => {
  switch (value) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return value;
  }
};

export default function CustomerProductTable() {
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(48);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [categories, setCategories] = React.useState([]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [categoryFilter, setCategoryFilter] = React.useState("");
  const [productQuantities, setProductQuantities] = React.useState({});
  const [addBasketSuccess, setAddBasketSuccess] = React.useState({});
  const [addingToBasket, setAddingToBasket] = React.useState({});
  const [addingBasketError, setAddingBasketError] = React.useState({});

  async function FetchProducts() {
    setLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/byPage`,
        {
          params: {
            page: currentPage,
            pageSize: pageSize,
            search: searchProduct === "" ? null : searchProduct,
            category: categoryFilter === "" ? null : categoryFilter,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        const fetchedProducts = res.data.products;
        const newProductQuantities = { ...productQuantities };
        fetchedProducts.forEach((product) => {
          if (newProductQuantities[product.id] === undefined) {
            newProductQuantities[product.id] = 0; // INITIALIZE WITH DEFAULT QUANTITY
          }
        });

        setProducts(res.data.products);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.totalCount);
        setCurrentPage(res.data.currentPage);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const handleQuantityChange = (productId, quantity) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  async function FetchCategories() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories/all`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        setCategories(response.data.categories);
      }
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    FetchProducts();
    FetchCategories();
  }, []);

  React.useEffect(() => {
    FetchProducts();
  }, [currentPage, searchProduct, categoryFilter]);

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Kategori</FormLabel>
        <Select
          size="sm"
          value={categoryFilter}
          onChange={(e, newValue) => setCategoryFilter(newValue)}
          placeholder="Kategori Seç"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="" key="all">
            Hepsi
          </Option>
          {categories.map((category, index) => (
            <Option key={index} value={category.id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );

  const AddToBasket = async (product) => {
    if (sessionStorage.getItem("id") === null) {
      alert("Please login to add to basket");
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/basket/add`,
        {
          product_id: product.id,
          customer_id: sessionStorage.getItem("id"),
          quantity: parseInt(productQuantities[product.id] || 0),
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (res.status === 200) {
        setAddingToBasket((prevAdding) => ({
          ...prevAdding,
          [product.id]: false, // Reset the loading flag for this product
        }));

        setAddBasketSuccess((prevSuccess) => ({
          ...prevSuccess,
          [product.id]: true, // Set the success flag for this product
        }));
        setTimeout(() => {
          setAddBasketSuccess((prevSuccess) => ({
            ...prevSuccess,
            [product.id]: false, // Reset the success flag for this product
          }));
        }, 2000);

        // Reset quantity on successful add
        setProductQuantities((prevQuantities) => ({
          ...prevQuantities,
          [product.id]: "", // Reset the quantity for this product
        }));
      }
    } catch (error) {
      setAddingToBasket((prevAdding) => ({
        ...prevAdding,
        [product.id]: false, // Reset the loading flag for this product
      }));
      setAddingBasketError((prevError) => ({
        ...prevError,
        [product.id]: true, // Set the error flag for this product
      }));
      setTimeout(() => {
        setAddingBasketError((prevError) => ({
          ...prevError,
          [product.id]: false, // Reset the error flag for this product
        }));
      }, 2000);

      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Sheet
        className="chAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Ürün Ara</FormLabel>
          <Input
            size="sm"
            placeholder="Ara"
            startDecorator={<SearchIcon />}
            onChange={(e) => {
              setSearchProduct(e.target.value);
            }}
            value={searchProduct}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "hidden",
          minHeight: 0,
          overflowY: "auto", // ALLOW VERTICAL SCROLLING
          overflowX: "hidden", // PREVENT HORIZONTAL SCROLLING
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Ürün Adı</TableCell>
                  <TableCell>Kategori</TableCell>
                  <TableCell align="center" width={150}>
                    Fiyat
                  </TableCell>
                  <TableCell align="center" width={50}>
                    Adet
                  </TableCell>
                  <TableCell>Ekle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <img
                        style={{ width: 30, height: 30, objectFit: "cover" }}
                        src={`data:image/jpeg;base64,${product.image}`}
                        alt={product.name}
                      />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {product.category_name}
                    </TableCell>
                    <TableCell align="center">
                      {product.total_price}{" "}
                      {currencySymbolFormatter(product.currency)}
                      {product.discount > 0 && product.discount < 100 && (
                        <Chip
                          component="span"
                          size="xsmall"
                          variant="soft"
                          color="success"
                        >
                          <DiscountIcon fontSize="small" /> {product.discount} %
                        </Chip>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl size="sm">
                        <Input
                          size="sm"
                          type="text"
                          sx={{ textAlign: "center", width: 80 }}
                          placeholder="Adet"
                          value={productQuantities[product.id]}
                          onChange={(e) => {
                            if (!isNaN(e.target.value) && e.target.value >= 0) {
                              handleQuantityChange(product.id, e.target.value);
                            } else if (e.target.value === "") {
                              handleQuantityChange(product.id, 0);
                            }
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="sm"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setAddingToBasket((prevAdding) => ({
                            ...prevAdding,
                            [product.id]: true,
                          }));
                          AddToBasket(product);
                        }}
                      >
                        <AddIcon />
                      </Button>
                      {addBasketSuccess[product.id] && (
                        <CheckCircleIcon
                          sx={{ color: "green", ml: 1 }}
                          fontSize="small"
                        />
                      )}
                      {addingToBasket[product.id] && (
                        <CircularProgress size={24} sx={{ ml: 1 }} />
                      )}
                      {addingBasketError[product.id] && (
                        <ReportProblemIcon
                          sx={{ color: "red", ml: 1 }}
                          fontSize="small"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={currentPage <= 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Geri
        </Button>

        <Box sx={{ flex: 1 }} />
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => {
            setCurrentPage(page);
          }}
        />
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={currentPage >= totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          İleri
        </Button>
      </Box>
    </React.Fragment>
  );
}
