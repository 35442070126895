import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { PageContext } from "../../Contexts/PageContext";
import Orders from "./Orders";
import DashboardIndex from "./Dashboard";
import Products from "./Products";
import CreateProduct from "./Products/Create";
import Customers from "./Customers";
import UserProfile from "./Users/UserProfile";
import Category from "./Category";
import CreateCustomer from "./Customers/Create";
import UserCreate from "./Users/UserCreate";
import UsersList from "./Users/UsersList";
import CreateCategory from "./Category/CreateCategory";
import Campaning from "./Campaning";

export default function Dashboard() {
  const { page, setPage, pages } = React.useContext(PageContext);

  React.useEffect(() => {
    const hash = window.location.hash.replace("#", "");

    if (hash) {
      setPage(hash);
    } else {
      window.location.hash = pages.dashboard;
      setPage(pages.dashboard);
    }
  }, []);

  // Function to determine which component to render based on the current page

  const renderComponent = () => {
    switch (page) {
      case pages.dashboard:
        return <DashboardIndex />;
      case pages.orders:
        return <Orders />;
      case pages.products:
        return <Products />;
      case pages.campaning:
        return <Campaning />;
      case pages.createProducts:
        return <CreateProduct />;
      case pages.category:
        return <Category />;
      case pages.createCategory:
        return <CreateCategory />;
      case pages.customers:
        return <Customers />;
      case pages.createCustomer:
        return <CreateCustomer />;
      // case pages.customers.delete:
      //   return <CustomerDelete />;
      // case pages.customers.update:
      //   return <CustomerUpdate />;
      case pages.users.profile:
        return <UserProfile />;
      case pages.users.create:
        return <UserCreate />;
      case pages.users.list:
        return <UsersList />;

      default:
        return <div>Page not found</div>;
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Sidebar />

        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          {/* HEADER  */}
          <Header />

          {/* COMPONENTS */}
          {renderComponent()}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
