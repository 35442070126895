import * as React from "react";
import CustomerList from "./CustomerList";
import CustomerTable from "./CustomerTable";



export default function Customers() {
  return (
    <React.Fragment>
<CustomerList/>
<CustomerTable/>

      </React.Fragment>
  );
}
