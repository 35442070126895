import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
// import { config } from '@/config';
import { LatestOrders } from './LatestOrders/index';
import { TotalCustomers } from './TotalCustomers/index';
import axios from "axios"
import { PendingOrders } from './PendingOrders';
import { TotalProducts } from './TotalProducts';
import { TotalOrders } from './TotalOrders';

// api/admin/dashboard/pending-orders (200 OK) (res.data) /GET

export default function DashboardIndex() {
  const [stats, setStats] = React.useState({})
  const [pendingOrders, setPendingOrders] = React.useState([])
  const [statsLoading, setStatsLoading] = React.useState(false)
  const [statsError, setStatsError] = React.useState({
    status: false,
    message: ""
  })

  React.useEffect(() => {
    async function fetchDashboardStats() {
      setStatsLoading(true)
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard/stats`, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          }
        })
        if (res.status === 200) {
          setStats(res.data.data);
        }
      } catch (err) {
        setStatsError({
          status: true,
          message: err.response.data.message,
        })
        if (err.response.status === 401) {
          sessionStorage.removeItem("id")
          sessionStorage.removeItem("token")
          window.location.href = "/admin/login"
        } else if (err.response.status === 400) {
          alert(err.response.data.message)
        }
        console.log(err);
      } finally {
        setStatsLoading(false);
      }
    }

    async function fetchPendingOrders() {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard/pending-orders`, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
          }
        })
        if (res.status === 200) {
          setPendingOrders(res.data.data)
        }
      } catch (err) {
        console.error(err)
      }

    }

    fetchPendingOrders()
    fetchDashboardStats()
  }, [])


  return (
    <Grid container spacing={3}>
      <Grid lg={3} sm={6} xs={12}>
        <TotalOrders diff={12} trend="up" sx={{ height: '100%' }} value={stats?.totalOrder || 0} />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <PendingOrders sx={{ height: '100%' }} value={stats?.totalPendingOrder || 0} />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <TotalProducts sx={{ height: '100%' }} value={stats.productCount || 0} />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <TotalCustomers diff={16} trend="down" sx={{ height: '100%' }} value={stats.customerCount} />
      </Grid>

      <Grid lg={12} md={12} xs={12}>
        <LatestOrders
          orders={pendingOrders || []}
          sx={{ height: '100%' }}
        />
      </Grid>
    </Grid>
  )
}