import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import { tabClasses } from "@mui/joy/Tab";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import axios from "axios";
import Alert from "@mui/joy/Alert";
import { PageContext } from "../../../../Contexts/PageContext";

export default function CreateCategory() {
  const { pages, setPage } = React.useContext(PageContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  const [newCategory, setNewCategory] = React.useState({
    name: "",
    description: "",
  });

  async function HandleCreateCategory() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/categories/create`,
        {
          name: newCategory.name,
          description: newCategory.description,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        console.log("Category created successfully");
        setPage(pages.category);
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        setError({
          status: true,
          message: "Unauthorized",
        });
      } else if (err.response.status === 400) {
        setError({
          status: true,
          message: "Bad Request",
        });
      } else {
        setError({
          status: true,
          message: "Internal Server Error",
        });
      }

      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            position: "sticky",
            top: { sm: -100, md: -110 },
            bgcolor: "background.body",
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}></Box>
          <Tabs
            defaultValue={0}
            sx={{
              bgcolor: "transparent",
            }}
          >
            <TabList
              tabFlex={1}
              size="sm"
              sx={{
                pl: { xs: 0, md: 4 },
                justifyContent: "left",
                [`&& .${tabClasses.root}`]: {
                  fontWeight: "600",
                  flex: "initial",
                  color: "text.tertiary",
                  [`&.${tabClasses.selected}`]: {
                    bgcolor: "transparent",
                    color: "text.primary",
                    "&::after": {
                      height: "2px",
                      bgcolor: "primary.500",
                    },
                  },
                },
              }}
            ></TabList>
          </Tabs>
        </Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "1200px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}></Box>

            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
            >
              <Stack direction="column" spacing={1}></Stack>
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={2}>
                  <FormControl sx={{ display: { xs: "none", md: "flex" } }}>
                    <FormLabel>Kategori Adı</FormLabel>
                    <Input
                      size="sm"
                      placeholder=""
                      sx={{ flexGrow: 1 }}
                      value={newCategory.name}
                      onChange={(e) =>
                        setNewCategory({
                          ...newCategory,
                          name: e.target.value,
                        })
                      }
                    />
                  </FormControl>

                  <FormControl
                    sx={{
                      display: { sm: "flex-column", md: "flex-row" },
                      gap: 1,
                    }}
                  >
                    <FormLabel>Açıklama (İsteğe bağlı) </FormLabel>
                    <Input
                      size="sm"
                      placeholder=""
                      value={newCategory.description}
                      onChange={(e) =>
                        setNewCategory({
                          ...newCategory,
                          description: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}></Stack>

                <Stack spacing={2}></Stack>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ display: { xs: "flex", md: "none" }, my: 1 }}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" spacing={1}></Stack>
              </Stack>
            </Stack>
            <CardOverflow
              sx={{ borderTop: "1px solid", borderColor: "divider" }}
            >
              <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                <Button
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  onClick={() => {
                    setPage(pages.category);
                  }}
                >
                  İptal
                </Button>
                <Button
                  size="sm"
                  variant="solid"
                  onClick={HandleCreateCategory}
                  loading={loading}
                >
                  Kaydet
                </Button>
              </CardActions>
            </CardOverflow>
            {error.status && (
              <Alert color="danger" variant="solid" hidden={!error.status}>
                {error.message}
              </Alert>
            )}
          </Card>
        </Stack>
      </Box>
    </React.Fragment>
  );
}
