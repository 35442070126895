import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
// import { config } from '@/config';
import { TotalWallet } from './TotalWallet/index';
import { TotalDebt } from './TotalDebt/index';
import axios from "axios"
import { PendingOrders } from './PendingOrders';
import { TotalOrders } from './TotalOrders';
import Carousel from './Carousel';
import { LatestOrders } from '../../Customer/Dashboard/LatestOrders';

// api/admin/dashboard/pending-orders (200 OK) (res.data) /GET

export default function CustomerDashboardIndex() {
  const [stats, setStats] = React.useState({})
  const [pendingOrders, setPendingOrders] = React.useState([])
  const [statsLoading, setStatsLoading] = React.useState(false)
  const [statsError, setStatsError] = React.useState({
    status: false,
    message: ""
  })
  const [campaigns, setCampaigns] = React.useState([]);
  const [loadingCampaigns, setLoadingCampaings] = React.useState(false);
  const [campaingsError, setCampaignsError] = React.useState({
    status: false,
    message: ""
  })

  React.useEffect(() => {
    async function fetchDashboardStats() {
      setStatsLoading(true)
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/stats`, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          }
        })
        if (res.status === 200) {
          setStats(res.data);
        }
      } catch (err) {
        setStatsError({
          status: true,
          message: err.response.data.message,
        })
        if (err.response.status === 401) {
          sessionStorage.removeItem("id")
          sessionStorage.removeItem("token")
          window.location.href = "/admin/login"
        } else if (err.response.status === 400) {
          alert(err.response.data.message)
        }
        console.log(err);
      } finally {
        setStatsLoading(false);
      }
    }

    async function fetchPendingOrders() {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/pending-orders`, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token")
          }
        })
        console.log(res);
        if (res.status === 200) {
          setPendingOrders(res.data.pendingOrders)
        }
      } catch (err) {
        console.error(err)
      }

    }

    async function fetchCampaings() {
      setLoadingCampaings(true)
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/campaigns`, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          }
        })
        if (res.status === 200) {
          setCampaigns(res.data.campaigns);
        }
      } catch (err) {
        setCampaignsError({
          status: true,
          message: err.response.data.message,
        })
        if (err.response.status === 401) {
          sessionStorage.removeItem("id")
          sessionStorage.removeItem("token")
          window.location.href = "/admin/login"
        } else if (err.response.status === 400) {
          alert(err.response.data.message)
        }
        console.log(err);
      } finally {
        setLoadingCampaings(false);
      }
    }

    fetchPendingOrders()
    fetchDashboardStats()
    fetchCampaings()
  }, [])


  return (
    <Grid container spacing={3} sx={{ overflowY: "scroll" }}>
      <Grid lg={3} sm={6} xs={12}>
        <TotalOrders diff={12} trend="up" sx={{ height: '100%' }} value={stats?.totalOrders || 0} />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <PendingOrders sx={{ height: '100%' }} value={stats?.pendingOrders || 0} />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <TotalDebt sx={{ height: '100%' }} value={stats?.totalDebt || 0} />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <TotalWallet sx={{ height: '100%' }} value={stats?.totalWallet || 0} />
      </Grid>

      <Grid lg={12} sm={12} xs={12}>
        <Carousel campaigns={campaigns?.map(campaign => ({
          image: campaign.image.Data,
        }))} />
      </Grid>

      <Grid lg={12} md={12} xs={12}>
        <LatestOrders
          orders={pendingOrders || []}
          sx={{ height: '100%' }}
        />
      </Grid>
    </Grid>
  )
}