/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from "axios";
import OrderDetailsModal from "../OrderDetailsModal";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const humanReadebleDate = (date) => {
  return new Date(date).toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const translateTR = (status) => {
  switch (status) {
    case "PENDING":
      return "BEKLEMEDE";
    case "DELIVERED":
      return "TESLİM EDİLDİ";
    case "SHIPPED":
      return "KARGOYA VERİLDİ";
    case "CANCELLED":
      return "İPTAL";
    case "REFUNDED":
      return "İADE EDİLDİ";
    default:
      return "";
  }
};

export default function CustomerOrderTable() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orders, setOrders] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);
  const [searchOrder, setSearchOrder] = React.useState("");
  const [orderStatusFilter, setOrderStatusFilter] = React.useState("");
  const [openDetailModal, setOpenDetailModal] = React.useState(false);
  const [orderDetail, setOrderDetail] = React.useState({});

  async function FetchOrders() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/byPage/${sessionStorage.getItem(
          "id"
        )}`,
        {
          params: {
            page: currentPage,
            pageSize: pageSize,
            search: searchOrder === "" ? null : searchOrder,
            status: orderStatusFilter === "" ? null : orderStatusFilter,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setOrders(res.data.orders);
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    FetchOrders();
  }, []);

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Durum</FormLabel>
        <Select
          size="sm"
          placeholder="Sipariş Durumu"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          value={orderStatusFilter}
          onChange={(e) => setOrderStatusFilter(e.target.value)}
        >
          <Option value="">Hepsi</Option>
          <Option value="PAID" disabled>
            Ödendi - Çok yakında
          </Option>
          <Option value="PENDING">Beklemede</Option>
          <Option value="DELIVERED">Teslim Edildi</Option>
          <Option value="SHIPPED">Kargoya Verildi</Option>
          <Option value="REFUNDED">İade Edildi</Option>
          <Option value="CANCELLED">İptal</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Ara"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Gönder
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Sipariş Ara</FormLabel>
          <Input
            size="sm"
            placeholder="Ara"
            startDecorator={<SearchIcon />}
            value={searchOrder}
            onChange={(e) => setSearchOrder(e.target.value)}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "12px 6px" }}>Sipariş Numarası</th>
              <th style={{ padding: "12px 6px" }}>Tarih</th>
              <th style={{ padding: "12px 6px" }}>Sipariş Durumu</th>
              <th style={{ padding: "12px 6px" }}>Hareketler</th>
            </tr>
          </thead>

          {/* DÜZELECEK YER */}
          <tbody>
            {orders &&
              stableSort(orders, getComparator(order, "id")).map(
                (row, index) => (
                  <tr key={row.id}>
                    <td>
                      <Typography level="body-xs">{row.order_code}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {humanReadebleDate(row.order_date)}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {translateTR(row.order_status)}
                      </Typography>
                      {/* <Chip
                        variant="soft"
                        size="sm"
                        startDecorator={
                          row.order_status === "PAID" ? (
                            <CheckRoundedIcon />
                          ) : row.order_status === "REFUNDED" ? (
                            <AutorenewRoundedIcon />
                          ) : row.order_status === "CANCELLED" ? (
                            <BlockIcon />
                          ) : row.order_status === "PENDING" ? (
                            <PendingIcon />
                          ) : row.order_status === "SHIPPED" ? (
                            <CheckRoundedIcon />
                          ) : null
                        }
                        color={
                          {
                            PAID: "success",
                            REFUNDED: "neutral",
                            CANCELLED: "danger",
                            PENDING: "warning", // Ensure this is a valid color value
                            SHIPPED: "info",
                          }[row.order_status] || "default" // Default color if status doesn't match
                        }
                      >
                        {row.order_status === "PAID"
                          ? "Ödendi"
                          : row.order_status === "REFUNDED"
                            ? "İade Edildi"
                            : row.order_status === "CANCELLED"
                              ? "İptal Edildi"
                              : row.order_status === "PENDING"
                                ? "Beklemede"
                                : row.order_status === "SHIPPED"
                                  ? "Kargoya Verildi"
                                  : null}
                      </Chip> */}
                    </td>
                    <td>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Button
                          level="body-xs"
                          component="button"
                          variant="success"
                          onClick={() => {
                            setOpenDetailModal(true);
                            setOrderDetail(row || []);
                          }}
                        >
                          İncele
                        </Button>
                      </Box>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={currentPage <= 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Geri
        </Button>

        <Box sx={{ flex: 1 }} />
        {Array.from({ length: totalPages }, (_, page) => (
          <IconButton
            key={page + 1}
            size="sm"
            variant={Number(page + 1) ? "outlined" : "plain"}
            color={currentPage === page + 1 ? "primary" : "neutral"}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={currentPage >= totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          İleri
        </Button>
      </Box>
      <Modal
        open={openDetailModal}
        onClose={() => setOpenDetailModal(false)}
        size="lg"
      >
        <ModalDialog
          aria-labelledby="order-details-modal"
          layout="center"
          sx={{
            width: "80%",
            bgcolor: "transparent",
            boxShadow: "none",
            p: 0,
            border: "none",
          }}
        >
          <OrderDetailsModal
            order={orderDetail}
            setOpenDetailModal={setOpenDetailModal}
            // discount={}w
          />
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
