import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import { tabClasses } from "@mui/joy/Tab";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import axios from "axios";
import DiscountIcon from "@mui/icons-material/Discount";
import Alert from "@mui/joy/Alert";
import { PageContext } from "../../../../Contexts/PageContext";

export default function EditCustomer({
  newCustomer,
  setNewCustomer,
  closeEdit,
  refreshCustomers,
}) {
  const { pages, setPage } = React.useContext(PageContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  const [tempCustomer, setTempCustomer] = React.useState(newCustomer);

  async function HandleUpdateCustomer() {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/customers/update/${newCustomer.id}`,
        {
          name: newCustomer.name,
          surname: newCustomer.surname,
          company_name: newCustomer.company_name,
          mail: newCustomer.mail,
          phone: newCustomer.phone,
          address: newCustomer.address,
          discount: parseFloat(newCustomer.discount),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        closeEdit();
        refreshCustomers();
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        setError({
          status: true,
          message: "Unauthorized",
        });
      } else if (err.response.status === 400) {
        setError({
          status: true,
          message: "Bad Request",
        });
      } else {
        setError({
          status: true,
          message: "Internal Server Error",
        });
      }

      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            position: "sticky",
            top: { sm: -100, md: -110 },
            bgcolor: "background.body",
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
              Müşteri Ekle
            </Typography>
          </Box>
          <Tabs
            defaultValue={0}
            sx={{
              bgcolor: "transparent",
            }}
          >
            <TabList
              tabFlex={1}
              size="sm"
              sx={{
                pl: { xs: 0, md: 4 },
                justifyContent: "left",
                [`&& .${tabClasses.root}`]: {
                  fontWeight: "600",
                  flex: "initial",
                  color: "text.tertiary",
                  [`&.${tabClasses.selected}`]: {
                    bgcolor: "transparent",
                    color: "text.primary",
                    "&::after": {
                      height: "2px",
                      bgcolor: "primary.500",
                    },
                  },
                },
              }}
            ></TabList>
          </Tabs>
        </Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "1200px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}></Box>

            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
            >
              <Stack direction="column" spacing={1}></Stack>
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={2}>
                  <FormLabel>İsim</FormLabel>
                  <FormControl
                    sx={{
                      display: { sm: "flex-column", md: "flex-row" },
                      gap: 1,
                    }}
                  >
                    <Input
                      size="sm"
                      placeholder="İsim"
                      value={newCustomer.name}
                      onChange={(e) =>
                        setNewCustomer({ ...newCustomer, name: e.target.value })
                      }
                    />
                    <FormLabel>Soyisim</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Soyisim"
                      sx={{ flexGrow: 1 }}
                      value={newCustomer.surname}
                      onChange={(e) =>
                        setNewCustomer({
                          ...newCustomer,
                          surname: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  <FormLabel>Şirket İsmi</FormLabel>
                  <FormControl
                    sx={{
                      display: { sm: "flex-column", md: "flex-row" },
                      gap: 1,
                    }}
                  >
                    <Input
                      size="sm"
                      placeholder="Şirket İsmi"
                      value={newCustomer.company_name}
                      onChange={(e) =>
                        setNewCustomer({
                          ...newCustomer,
                          company_name: e.target.value,
                        })
                      }
                    />
                    <FormLabel>Adres</FormLabel>
                    <Input
                      size="sm"
                      placeholder="Adres"
                      sx={{ flexGrow: 1 }}
                      value={newCustomer.address}
                      onChange={(e) =>
                        setNewCustomer({
                          ...newCustomer,
                          address: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Telefon</FormLabel>
                    <Input
                      size="sm"
                      type="tel"
                      placeholder="Telefon"
                      value={newCustomer.phone}
                      onChange={(e) =>
                        setNewCustomer({
                          ...newCustomer,
                          phone: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      size="sm"
                      type="email"
                      startDecorator={<EmailRoundedIcon />}
                      placeholder="email"
                      sx={{ flexGrow: 1 }}
                      value={newCustomer.mail}
                      onChange={(e) =>
                        setNewCustomer({ ...newCustomer, mail: e.target.value })
                      }
                    />
                  </FormControl>
                </Stack>
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>Müşteri Özel İndirim</FormLabel>
                  <Input
                    size="sm"
                    type="email"
                    startDecorator={<DiscountIcon />}
                    placeholder="%"
                    sx={{ flexGrow: 1 }}
                    value={newCustomer.discount}
                    onChange={(e) =>
                      setNewCustomer({
                        ...newCustomer,
                        discount: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ display: { xs: "flex", md: "none" }, my: 1 }}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" spacing={1}>
                  <AspectRatio
                    ratio="1"
                    maxHeight={108}
                    sx={{ flex: 1, minWidth: 108, borderRadius: "100%" }}
                  >
                    <img
                      src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                      srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                      loading="lazy"
                      alt=""
                    />
                  </AspectRatio>
                  <IconButton
                    aria-label="upload new picture"
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{
                      bgcolor: "background.body",
                      position: "absolute",
                      zIndex: 2,
                      borderRadius: "50%",
                      left: 85,
                      top: 180,
                      boxShadow: "sm",
                    }}
                  >
                    <EditRoundedIcon />
                  </IconButton>
                </Stack>
                <Stack spacing={1} sx={{ flexGrow: 1 }}>
                  <FormLabel>Name</FormLabel>
                  <FormControl
                    sx={{
                      display: {
                        sm: "flex-column",
                        md: "flex-row",
                      },
                      gap: 2,
                    }}
                  >
                    <Input size="sm" placeholder="First name" />
                    <Input size="sm" placeholder="Last name" />
                  </FormControl>
                </Stack>
              </Stack>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Email</FormLabel>
                <Input
                  size="sm"
                  type="email"
                  startDecorator={<EmailRoundedIcon />}
                  placeholder="email"
                  defaultValue="siriwatk@test.com"
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>
            </Stack>
            <CardOverflow
              sx={{ borderTop: "1px solid", borderColor: "divider" }}
            >
              <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                <Button
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  onClick={() => {
                    setNewCustomer(tempCustomer);
                    closeEdit();
                  }}
                >
                  İptal
                </Button>
                <Button
                  size="sm"
                  variant="solid"
                  onClick={HandleUpdateCustomer}
                  loading={loading}
                >
                  Kaydet
                </Button>
              </CardActions>
            </CardOverflow>
            {error.status && (
              <Alert color="danger" variant="solid" hidden={!error.status}>
                {error.message}
              </Alert>
            )}
          </Card>
        </Stack>
      </Box>
    </React.Fragment>
  );
}
