import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Arrow components
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: "primary.secondary", // Custom color
        position: "absolute",
        top: "50%",
        right: "-25px",
        zIndex: 1,
        transform: "translateY(-50%)",
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: "primary.secondary", // Custom color
        position: "absolute",
        top: "50%",
        left: "-25px",
        zIndex: 1,
        transform: "translateY(-50%)",
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
}

export default function Carousel({ campaigns = [] }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: "auto",
        padding: "20px",
        position: "relative",
      }}
    >
      <Slider {...settings}>
        {campaigns.map((campaign, index) => (
          <Box
            key={index}
            sx={{
              height: 200,
              width: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              boxShadow: 3, // MUI shadow scale
              borderRadius: "10px", // Rounded corners
              "&:hover": {
                transform: "scale(1.05)", // Scale effect on hover
                transition: "transform .3s",
              },
            }}
          >
            <img
              src={`data:image/jpeg;base64,${campaign.image}`}
              alt={campaign.label || `Campaign ${index}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
