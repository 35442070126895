import * as React from "react";
import CustomerOrderList from "./OrderList";
import CustomerOrderTable from "./OrderTable";

export default function CustomerOrders() {
  return (
    <React.Fragment>
      <CustomerOrderTable />
      <CustomerOrderList />
    </React.Fragment>
  );
}
