/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import { PageContext } from "../../../../Contexts/PageContext";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/joy";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import EditProduct from "../Edit";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function currencyFormat(num) {
  if (num === "TRY") {
    return "₺";
  } else if (num === "USD") {
    return "$";
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function RowMenu({
  setEdit,
  setEditProduct,
  row,
  openDeleteConfirmation,
  setDeleteID,
}) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem
          startDecorator={<ModeEditIcon />}
          onClick={() => {
            setEdit(true);
            setEditProduct(row);
          }}
        >
          Düzenle
        </MenuItem>
        <MenuItem
          startDecorator={<DeleteIcon />}
          onClick={() => {
            openDeleteConfirmation();
            setDeleteID(row.id);
          }}
        >
          Sil
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default function ProductTable() {
  const [order, setOrder] = React.useState("asc"); // ORDER : ASC, DESC - DEFAULT : ASC - ID SORT PURPOSE
  const [deleteID, setDeleteID] = React.useState("");
  const [editProduct, setEditProduct] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [categories, setCategories] = React.useState([]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [deleting, setDeleting] = React.useState(false);
  const [categoryFilter, setCategoryFilter] = React.useState("");
  const [priceChangeOpen, setPriceChangeOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [newPrice, setNewPrice] = React.useState("");
  const [priceChangeType, setPriceChangeType] = React.useState("increase");
  const [priceChangeStatus, setPriceChangeStatus] = React.useState("idle");

  const { setPage, pages } = React.useContext(PageContext);

  async function handleDeleteProduct() {
    setDeleting(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/products/delete/${deleteID}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setDeleting(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setDeleting(false);
    }
  }

  async function FetchProducts() {
    setLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/products/byPage`,
        {
          params: {
            page: currentPage,
            pageSize: pageSize,
            search: searchProduct === "" ? null : searchProduct,
            category: categoryFilter === "" ? null : categoryFilter,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setProducts(res.data.products);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.totalCount);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const regetProducts = () => {
    FetchProducts();
  };

  async function FetchCategories() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/categories/all`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        setCategories(response.data.categories);
      }
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    FetchProducts();
    FetchCategories();
  }, []);

  React.useEffect(() => {
    FetchProducts();
  }, [currentPage, searchProduct, categoryFilter]);

  function openDeleteConfirmation() {
    setDeleteConfirmation(true);
  }

  async function changeCategoryPrice(categoryID, amount, type) {
    setPriceChangeStatus("pending");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/products/percentagePriceChange`,
        {
          categoryID,
          amount,
          type,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setPriceChangeStatus("success");
      }
    } catch (error) {
      console.log(error);
      setPriceChangeStatus("error");
    }
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Kategori</FormLabel>
        <Select
          size="sm"
          value={categoryFilter}
          onChange={(e, newValue) => setCategoryFilter(newValue)}
          placeholder="Kategori Seç"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="" key="all">
            Hepsi
          </Option>
          {categories?.map((category, index) => (
            <Option key={index} value={category.id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Yeni Oluştur</FormLabel>
        <Button
          size="sm"
          variant="outlined"
          color="primary"
          onClick={() => {
            setPage(pages.createProducts);
            window.location.hash = pages.createProducts;
          }}
          startDecorator={<Add />}
        >
          Yeni Ürün Ekle
        </Button>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Kategori Fiyat Değişimi</FormLabel>
        <Button
          size="sm"
          variant="outlined"
          color="primary"
          onClick={() => setPriceChangeOpen(true)}
        >
          Yüzdelik Fiyat Değiştir
        </Button>
      </FormControl>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Sheet
        className="chAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Ürün Ara</FormLabel>
          <Input
            size="sm"
            placeholder="Ara"
            startDecorator={<SearchIcon />}
            onChange={(e) => {
              setSearchProduct(e.target.value);
            }}
            value={searchProduct}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "820px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>
                <th style={{ width: 120, padding: "12px 6px" }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                      },
                    }}
                  >
                    Ürün
                  </Link>
                </th>
                <th style={{ width: 140, padding: "12px 6px" }}>Açıklama</th>
                <th style={{ width: 140, padding: "12px 6px" }}>Kategori</th>
                <th style={{ width: 240, padding: "12px 6px" }}>Fiyat</th>
                <th style={{ width: 120, padding: "12px 6px" }}>İskonto</th>
                <th style={{ width: 120, padding: "12px 6px" }}>Hareketler</th>
              </tr>
            </thead>
            <tbody>
              {products &&
                stableSort(products, getComparator(order, "id"))?.map(
                  (row, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center", width: 120 }}>
                        <img
                          src={`data:image/jpeg;base64,${row.image}`}
                          alt={row.name}
                          style={{ width: 50, height: 50 }}
                        />
                      </td>
                      <td>
                        <Typography level="body-xs">{row.name}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs" className="truncate">
                          {truncateText(row.description, 50)}
                        </Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">
                          {row.category_name}
                        </Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">
                          {row.total_price} {currencyFormat(row.currency)}
                        </Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">
                          % {row.discount}
                        </Typography>
                      </td>
                      <td
                        sx={{
                          width: 120,
                        }}
                      >
                        <RowMenu
                          setEdit={setEdit}
                          setEditProduct={setEditProduct}
                          row={row}
                          openDeleteConfirmation={openDeleteConfirmation}
                          setDeleteID={setDeleteID}
                        />
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        )}
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={currentPage <= 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Geri
        </Button>

        <Box sx={{ flex: 1 }} />
        {Array.from({ length: totalPages }, (_, page) => (
          <IconButton
            key={page + 1}
            size="sm"
            variant={Number(page + 1) ? "outlined" : "plain"}
            color={currentPage === page + 1 ? "primary" : "neutral"}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={currentPage >= totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          İleri
        </Button>
      </Box>
      <Modal open={edit} onClose={() => setEdit(false)}>
        <ModalDialog aria-labelledby="edit-modal" layout="center">
          <DialogTitle id="edit-modal">Ürün Düzenleme</DialogTitle>
          <Divider />
          <DialogContent>
            <EditProduct
              product={editProduct}
              setEdit={setEdit}
              refetch={regetProducts}
            />
          </DialogContent>
        </ModalDialog>
      </Modal>

      <Modal
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Ürün Silme
          </DialogTitle>
          <Divider />
          <DialogContent>
            Bu ürünün silinmesi durumunda, bu işlem geri alınamaz. Bu ürüne
            sahip olan sepetler bu eylemden etkilenecektir. Devam etmek
            istediğinize emin misiniz?
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              loading={deleting}
              onClick={() => handleDeleteProduct()}
            >
              SİL
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setDeleteConfirmation(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={priceChangeOpen} onClose={() => setPriceChangeOpen(false)}>
        <ModalDialog aria-labelledby="price-change-modal" layout="center">
          <DialogTitle id="price-change-modal">
            Yüzdelik Fiyat Değişimi
          </DialogTitle>
          <Divider />
          <DialogContent>
            {priceChangeStatus === "pending" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {priceChangeStatus === "success" && (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <Typography level="h5" sx={{ mb: 2 }}>
                  Başarıyla Gerçekleştirildi!
                </Typography>
                <Button
                  variant="solid"
                  color="primary"
                  onClick={() => {
                    setPriceChangeStatus("idle");
                    setSelectedCategory("");
                    setNewPrice("");
                    setPriceChangeOpen(false);
                    setPriceChangeType("increase");
                  }}
                >
                  Kapat
                </Button>
              </Box>
            )}
            {priceChangeStatus === "error" && (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <Typography level="h5" sx={{ mb: 2, color: "error.main" }}>
                  Yapılamadı!
                </Typography>
                <Button
                  variant="solid"
                  color="danger"
                  onClick={() => {
                    setPriceChangeStatus("idle");
                    setSelectedCategory("");
                    setNewPrice("");
                    setPriceChangeType("increase");
                    setPriceChangeOpen(false);
                  }}
                >
                  Kapat
                </Button>
              </Box>
            )}
            {priceChangeStatus === "idle" && (
              <>
                <FormControl size="sm" fullWidth>
                  <FormLabel>Kategori Seç</FormLabel>
                  <Select
                    size="sm"
                    value={selectedCategory}
                    onChange={(e, newValue) => setSelectedCategory(newValue)}
                    placeholder="Kategori Seç"
                  >
                    {categories?.map((category, index) => (
                      <Option key={index} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="sm" fullWidth sx={{ mt: 2 }}>
                  <FormLabel>Yüzdelik Zam</FormLabel>
                  <Input
                    size="sm"
                    type="number"
                    placeholder="%"
                    inputProps={{ min: 0 }}
                    value={newPrice}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      setNewPrice(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl size="sm" fullWidth sx={{ mt: 2 }}>
                  <FormLabel>Zam - İndirim</FormLabel>
                  <Select
                    size="sm"
                    value={priceChangeType}
                    onChange={(e, newValue) => setPriceChangeType(newValue)}
                    placeholder="Zam - İndirim"
                  >
                    <Option value="increase">Zam</Option>
                    <Option value="decrease">İndirim</Option>
                  </Select>
                </FormControl>
                <DialogActions>
                  <Button
                    variant="solid"
                    color="primary"
                    onClick={() => {
                      changeCategoryPrice(
                        selectedCategory,
                        newPrice,
                        priceChangeType
                      );
                    }}
                  >
                    Değiştir
                  </Button>
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={() => {
                      setPriceChangeOpen(false);
                      setSelectedCategory("");
                      setNewPrice("");
                      setPriceChangeType("increase");
                    }}
                  >
                    İptal
                  </Button>
                </DialogActions>
              </>
            )}
          </DialogContent>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}
