import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import Button from "@mui/joy/Button";
import { Box, Grid, Modal, ModalClose, ModalDialog } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import { CardActions, CircularProgress } from '@mui/material';
import InputFileUpload from './FileUploud';
import axios from 'axios';

export default function Campaning() {
    const [open, setOpen] = React.useState(false);
    const [photo, setPhoto] = React.useState(null);
    const [photoURL, setPhotoURL] = React.useState("");
    const [campaigns, setCampaigns] = React.useState([]);
    const [creating, setCreating] = React.useState(false);
    const [showImage, setShowImage] = React.useState(false)
    const [showImageData, setShowImageDate] = React.useState(false)
    const [loading, setLoading] = React.useState(false);

    async function fetchCampaigns() {
        setLoading(true)
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/campaigns/all`, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            })

            if (res.status === 200) {
                setCampaigns(res.data)
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        fetchCampaigns()
    }, [])

    async function deleteCampaign(id) {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_API_URL}/admin/campaigns/${id}`, {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("token")
                }
            })

            if (res.status === 200) {
                setCampaigns(campaigns.filter(e => e._id !== id))
            }
        } catch (err) {
            console.log(err);
        }
    }


    async function handleCreateCampaign() {
        setCreating(true)
        const formData = new FormData();
        // NO NEED TO CHECK PHOTO AGAIN HERE, ALREADY VALIDATED
        formData.append("image", photo);

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/campaigns/create`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + sessionStorage.getItem("token"),
                    },
                }
            );

            if (res.status === 201) {
                setOpen(false)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setCreating(false)
            fetchCampaigns()
        }
    }

    const handleAddPic = () => {
        document.getElementById("photoInput").click();
    };

    const handleChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPhoto(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoURL(reader.result);
            };
            reader.readAsDataURL(file);
            setOpen(true)
        }
    };


    return (
        <>
            <input
                type="file"
                id="photoInput"
                style={{ display: "none" }}
                onChange={handleChange}
                accept="image/*"
            />
            <Button
                color='success'
                startDecorator={<Add />}
                onClick={handleAddPic}>Kampanya Ekle</Button>

            <Modal open={open} onClose={() => setOpen(false)} >
                <ModalDialog layout='center'
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 0,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                        width: "25%"
                    })}
                >
                    <img src={photoURL} loading="lazy" alt="Product" />
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            gap: 1,
                            flexDirection: { xs: 'column', sm: 'row-reverse' },
                        }}
                    >
                        <Button variant="outlined"
                            color="neutral"
                            onClick={handleCreateCampaign}
                            loading={creating}
                        >
                            Kampanya Oluştur
                        </Button>
                        <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => {
                                setOpen(false)
                                setPhotoURL("")
                                setPhoto(null)
                            }}
                        >
                            İptal
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
            <Box sx={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}>
                <Grid
                    container
                    spacing={{ xs: 2, md: 4 }}
                    columns={{ xs: 3, sm: 8, md: 8 }}
                    sx={{ flexGrow: 1 }}
                >

                    {loading ? <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }} >
                        <CircularProgress sx={{ width: "100%" }} /></Box> :
                        <>
                            {campaigns?.map((campaign, index) => {
                                console.log(campaign);
                                console.log("asd");
                                return (
                                    <Grid xs={12} sm={6} md={2} xl={2} mt={5} key={index}>
                                        <Card
                                            variant="outlined"
                                        >
                                            <CardContent>
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        setShowImage(true);
                                                    }}
                                                >
                                                    <img
                                                        src={`data:image/jpeg;base64,${campaign.image.Data}`}
                                                        loading="lazy"
                                                        alt=""
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                            </CardContent>
                                            <CardActions>
                                                <Button sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }} variant="solid" color="danger" onClick={() => {
                                                    deleteCampaign(campaign._id)
                                                }} >
                                                    Sil
                                                </Button>
                                            </CardActions>
                                        </Card>
                                        <Modal open={showImage} onClose={() => setShowImage(false)} >
                                            <ModalDialog layout='center'
                                                aria-labelledby="nested-modal-title"
                                                aria-describedby="nested-modal-description"
                                                sx={(theme) => ({
                                                    [theme.breakpoints.only('xs')]: {
                                                        top: 'unset',
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        borderRadius: 0,
                                                        transform: 'none',
                                                        maxWidth: 'unset',
                                                    },
                                                    width: "25%"
                                                })}

                                            >
                                                <ModalClose />

                                                <img src={`data:image/jpeg;base64,${campaign.image.Data}`} loading="lazy" />
                                            </ModalDialog>
                                        </Modal>
                                    </Grid>
                                )
                            })}</>
                    }
                </Grid>
            </Box>
        </>
    );
}