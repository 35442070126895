/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import { PageContext } from "../../../../Contexts/PageContext";
import {
  Alert,
  Avatar,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/joy";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import EditCustomer from "../Edit";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export default function CustomerTable() {
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [open, setOpen] = React.useState(false);
  const { setPage, pages } = React.useContext(PageContext);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const [deleteCustomerId, setDeleteCustomerId] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  const [editCustomer, setEditCustomer] = React.useState(false);
  const [editCustomerData, setEditCustomerData] = React.useState({});

  async function FetchCustomers() {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/customers/byPage`,
        {
          params: {
            page: currentPage,
            pageSize: pageSize,
            search: searchCustomer === "" ? null : searchCustomer,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setRows(res.data.customers);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.totalCount);
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 400) {
        setError({
          status: true,
          message: err.response.data.message,
        });
      } else {
        setError({
          status: true,
          message: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
        });
      }

      setLoading(false);
    }
  }

  React.useEffect(() => {
    FetchCustomers();
  }, []);

  React.useEffect(() => {
    FetchCustomers();
  }, [currentPage, searchCustomer, pageSize]);

  async function HandleDeleteCustomer(id) {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/customers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setRows(rows?.filter((row) => row.id !== id));
      }
    } catch (err) {
      if (err.response.status === 400) {
        setError({
          status: true,
          message: err.response.data.message,
        });
      } else {
        setError({
          status: true,
          message: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
        });
      }
    }
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Yeni Müşteri Oluştur</FormLabel>
        <Button
          size="sm"
          variant="outlined"
          color="primary"
          onClick={() => {
            setPage(pages.createCustomer);
            window.location.hash = pages.createCustomer;
          }}
          startDecorator={<Add />}
        >
          Müşteri Ekle
        </Button>
      </FormControl>
    </React.Fragment>
  );

  const refreshCustomers = () => {
    FetchCustomers();
  };

  if (editCustomer) {
    return (
      <EditCustomer
        refreshCustomers={refreshCustomers}
        newCustomer={editCustomerData}
        setNewCustomer={setEditCustomerData}
        closeEdit={() => setEditCustomer(false)}
      />
    );
  }

  return (
    <React.Fragment>
      <Sheet
        className="chAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Ara"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Müşteri Ara</FormLabel>
          <Input
            size="sm"
            placeholder="Ara"
            startDecorator={<SearchIcon />}
            onChange={(e) => {
              setSearchCustomer(e.target.value);
              FetchCustomers();
            }}
            value={searchCustomer}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "820px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: 50,
                    textAlign: "center",
                    padding: "25px 6px",
                  }}
                ></th>
                <th style={{ width: 120, padding: "12px 6px" }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                      },
                    }}
                  >
                    İsim Soyisim
                  </Link>
                </th>
                <th style={{ width: 140, padding: "12px 6px" }}>Şirket İsmi</th>
                <th typeof="e-mail" style={{ width: 140, padding: "12px 6px" }}>
                  E-mail
                </th>
                <th style={{ width: 120, padding: "12px 6px" }}>
                  Telefon Numarası
                </th>
                <th style={{ width: 240, padding: "12px 6px" }}>Adres</th>
                <th style={{ width: 140, padding: "12px 6px" }}>İndirim</th>
                <th style={{ width: 120, padding: "12px 6px" }}>Hareketler</th>
              </tr>
            </thead>

            <tbody>
              {stableSort(rows, getComparator(order, "id"))?.map((row) => (
                <tr key={row.id}>
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Avatar>
                      {String(row.company_name).trim().charAt(0).toUpperCase()}
                    </Avatar>
                  </td>
                  <td>
                    <Typography level="body-xs">
                      {row.name} {row.surname}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{row.company_name}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{row.mail}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{row.phone}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{row.address}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">% {row.discount}</Typography>
                  </td>
                  <td>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <IconButton
                        onClick={() => {
                          setEditCustomer(true);
                          setEditCustomerData(row);
                        }}
                      >
                        <ModeEditIcon
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setDeleteConfirmation(true);
                          setDeleteCustomerId(row.id);
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Sheet>
      {error.status && (
        <Alert variant="solid" color="danger">
          {error.message}
        </Alert>
      )}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={currentPage <= 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Geri
        </Button>

        <Box sx={{ flex: 1 }} />
        {Array.from({ length: totalPages }, (_, page) => (
          <IconButton
            key={page + 1}
            size="sm"
            variant={Number(page + 1) ? "outlined" : "plain"}
            color={currentPage === page + 1 ? "primary" : "neutral"}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={currentPage >= totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          İleri
        </Button>
      </Box>
      <Modal
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Müşteri Silme
          </DialogTitle>
          <Divider />
          <DialogContent>
            Bu müşterinin silinmesi durumunda, bu işlem geri alınamaz. Bu
            müşteriye sahip olan siparişler bu eylemden etkilenmeyecek fakat
            siparişler filtreleme ekranında çıkmayacaktır. Devam etmek
            istediğinize emin misiniz?
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                HandleDeleteCustomer(deleteCustomerId);
                setDeleteConfirmation(false);
              }}
            >
              Sil
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setDeleteConfirmation(false)}
            >
              İptal
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
