import * as React from "react";
import CustomerProductTable from "./ProductTable";
import CustomerProductList from "./ProductList";

export default function CustomerProductsPage() {
  return (
    <React.Fragment>
      <CustomerProductTable />
      <CustomerProductList />
    </React.Fragment>
  );
}
