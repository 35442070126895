import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Header from "./Header";
import { CustomerPageContext } from "../../Contexts/CustomerPageContext";
import CustomerSidebar from "./Sidebar";
import CustomerDashboardIndex from "./Dashboard";
import CustomerProductsPage from "./Products";
import CustomerProfile from "./UserProfile";
import Basket from "./Basket";
import CustomerOrders from "./Orders";

export default function CustomerDashboard() {
  const { page, setPage, pages } = React.useContext(CustomerPageContext);

  React.useEffect(() => {
    const hash = window.location.hash.replace("#", "");

    if (hash) {
      setPage(hash);
    } else {
      window.location.hash = pages.dashboard;
      setPage(pages.dashboard);
    }
  }, []);

  const renderComponent = () => {
    switch (page) {
      case pages.dashboard:
        return <CustomerDashboardIndex />;
      case pages.products:
        return <CustomerProductsPage />;
      case pages.profile:
        return <CustomerProfile />;
      case pages.shopping_cart:
        return <Basket />;
      case pages.orders:
        return <CustomerOrders />;
      default:
        return <div>Page not found</div>;
    }
  };

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <CustomerSidebar />

        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          {/* HEADER  */}
          <Header />

          {/* COMPONENTS */}
          {renderComponent()}
        </Box>
      </Box>
    </>
  );
}
