import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import { ArrowRight as ArrowRightIcon } from "@phosphor-icons/react/dist/ssr/ArrowRight";
import { Typography } from "@mui/material";
import Table from "@mui/joy/Table";
import { PageContext } from "../../../../Contexts/PageContext";
import { CustomerPageContext } from "../../../../Contexts/CustomerPageContext";

const statusMap = {
  pending: { label: "Pending", color: "warning" },
  delivered: { label: "Delivered", color: "success" },
  refunded: { label: "Refunded", color: "error" },
};

const humanReadebleDate = (date) => {
  return new Date(date).toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const translateTR = (status) => {
  switch (status) {
    case "PENDING":
      return "BEKLEMEDE";
    case "DELIVERED":
      return "TESLİM EDİLDİ";
    case "SHIPPED":
      return "KARGOYA VERİLDİ";
    case "REFUNDED":
      return "İADE EDİLDİ";
    case "CANCELLED":
      return "IPTAL";
  }
};

export function LatestOrders({ orders = [], sx }) {
  const { pages, setPage } = React.useContext(CustomerPageContext);
  return (
    <Card variant="solid" color="neutral" invertedColors>
      <Typography variant="h6">Son Siparişler</Typography>
      <Divider />
      <Box sx={{ overflowX: "auto" }}>
        <Table hoverRow>
          <thead>
            <tr>
              <th>Sipariş Numarası</th>
              <th>Müşteri</th>
              <th>Tarih</th>
              <th>Durum</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order, index) => {
              return (
                <tr key={index}>
                  <td>{order.order_code}</td>
                  <td>{order.customer.company_name}</td>
                  <td>{humanReadebleDate(order.order_date)} </td>
                  <td>{translateTR(order.order_status)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={<ArrowRightIcon fontSize="var(--icon-fontSize-md)" />}
          size="small"
          variant="text"
          onClick={() => {
            window.location.href = "/dashboard#" + pages.orders;
            setPage(pages.orders);
          }}
        >
          Tüm Siparişleri Gör
        </Button>
      </CardActions>
    </Card>
  );
}
