import "./index.css";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Admin/Login";
import Dashboard from "./components/Admin";
import PageProvider from "./Contexts/PageContext";

// CUSTOMER
import CustomerLogin from "./components/Customer/Login";
import PageProviderCustomer from "./Contexts/CustomerPageContext";
import CustomerDashboard from "./components/Customer";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/*" element={<CustomerRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

function CustomerRoutes() {
  // GET SESSION TOKEN
  if (
    !sessionStorage.getItem("token") &&
    window.location.pathname !== "/login"
  ) {
    window.location.href = "/login";
  }

  return (
    <Routes>
      <Route path="login" element={<CustomerLogin />} />
      <Route
        path="dashboard"
        element={
          <PageProviderCustomer>
            <CustomerDashboard />
          </PageProviderCustomer>
        }
      />
    </Routes>
  );
}

function AdminRoutes() {
  // GET SESSION TOKEN
  if (
    !sessionStorage.getItem("token") &&
    window.location.pathname !== "/admin/login" &&
    window.location.pathname !== "/login"
  ) {
    window.location.href = "/admin/login";
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="dashboard"
        element={
          <PageProvider>
            <Dashboard />
          </PageProvider>
        }
      />
    </Routes>
  );
}

export default App;
