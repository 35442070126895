import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import axios from "axios";

const humanReadableDate = (date) => {
  const d = new Date(date);
  // DD/MM/YYYY
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

const germanPriceFormat = (price) => {
  // 1.000,00
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

function formatPriceToGermanStandard(totalPrice) {
  // PARSE THE PRICE TO FLOAT AND FIX IT TO TWO DECIMAL PLACES
  const price = parseFloat(totalPrice).toFixed(2);

  // CONVERT THE PRICE TO GERMAN STANDARD FORMAT
  const germanFormattedPrice = parseFloat(price).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return germanFormattedPrice;
}

function currencyFormatter(currency) {
  // FORMAT CURRENCY SYMBOL
  switch (currency) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    default:
      return "";
  }
}

export default function OrderDetailsModal({ order, setOpenDetailModal }) {
  const statusSteps = ["PENDING", "SHIPPED", "DELIVERED"];
  const currentStatusIndex = statusSteps.indexOf(order.order_status);
  const [customer, setCustomer] = React.useState({
    name: "",
    surname: "",
    discount: 0.0,
  });

  React.useEffect(() => {
    const getCustomer = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/customer/current`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (res.status === 200) {
          console.log(res.data);
          setCustomer(res.data.customer);
        }
      } catch (error) {
        console.error(error);
      }
    };
  }, []);

  return (
    <>
      <section
        className="h-100 h-custom"
        style={{
          backgroundColor: "transparent",
          border: "none",
          scrollBehavior: "smooth",
          overflowY: "auto",
        }}
      >
        <MDBContainer className="py-5 h-100">
          <MDBRow className="justify-content-center align-items-center h-100">
            <MDBCol lg="8" xl="6">
              <MDBCard className="border-top border-bottom border-3 border-color-custom">
                <MDBCardBody className="p-5">
                  <p className="lead fw-bold mb-5 text-success">
                    Sipariş Detayları
                  </p>

                  <MDBRow>
                    <MDBCol className="mb-3">
                      <p className="small text-muted mb-1">Date</p>
                      <p>{humanReadableDate(order.order_date)}</p>
                    </MDBCol>
                    <MDBCol className="mb-3">
                      <p className="small text-muted mb-1">Order No.</p>
                      <p>{order.order_code}</p>
                    </MDBCol>
                  </MDBRow>

                  <div
                    className="mx-n5 px-1 py-4"
                    style={{ backgroundColor: "#f2f2f2" }}
                  >
                    {/* HEADER */}
                    <Stack
                      direction="row"
                      spacing={4}
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        width: "100%",
                        marginBottom: 2,
                      }}
                    >
                      <Box sx={{ width: 1 / 5, textAlign: "center" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          Ürün
                        </Typography>
                      </Box>
                      <Box sx={{ width: 1 / 5, textAlign: "center" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          Adet
                        </Typography>
                      </Box>

                      <Box sx={{ width: 1 / 5, textAlign: "center" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          Fiyat
                        </Typography>
                      </Box>
                      <Box sx={{ width: 1 / 5, textAlign: "center" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          Ürün Adı
                        </Typography>
                      </Box>
                      <Box sx={{ width: 1 / 5, textAlign: "center" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          Toplam
                        </Typography>
                      </Box>
                    </Stack>
                    {/* PRODUCTS */}
                    {order.products &&
                      order.products.map((product, index) => {
                        let subTotal =
                          product.total_price *
                          product.quantity *
                          (1 - product.discount / 100) *
                          (1 - order.customer.discount / 100);

                        let tax = subTotal * 0.2;
                        return (
                          <Stack
                            direction="row"
                            spacing={4}
                            key={index}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              width: "100%",
                              marginBottom:
                                index !== order.products.length - 1 ? 2 : 0, // Add margin bottom except for the last item
                              marginTop: index !== 0 ? 2 : 0, // Add margin top except for the first item
                            }}
                          >
                            {/* SMALL IMAGE */}
                            <Box
                              sx={{
                                width: 1 / 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`data:image/jpeg;base64,${product.image.Data}`}
                                alt={product.name}
                                width={50}
                                height={50}
                                style={{ maxHeight: 150, maxWidth: "100%" }}
                              />
                            </Box>
                            {/* PRODUCT NAME */}
                            <Box sx={{ width: 1 / 5, textAlign: "left" }}>
                              <Typography
                                variant="body2"
                                className="custom-tooltip"
                                sx={{ fontSize: "0.6rem" }}
                              >
                                {product.quantity} Adet
                              </Typography>
                            </Box>
                            <Box sx={{ width: 1 / 5, textAlign: "left" }}>
                              <Typography
                                variant="body2"
                                className="custom-tooltip"
                                sx={{ fontSize: "0.6rem" }}
                              >
                                {product.price}{" "}
                                {currencyFormatter(product.currency)}
                              </Typography>
                            </Box>
                            <Box sx={{ width: 1 / 5, textAlign: "left" }}>
                              <Typography
                                variant="body2"
                                className="custom-tooltip"
                                sx={{ fontSize: "0.4rem" }}
                              >
                                {product.name}
                              </Typography>
                            </Box>
                            {/* PRODUCT PRICE */}
                            <Box sx={{ width: 1 / 5, textAlign: "center" }}>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "0.6rem" }}
                              >
                                {formatPriceToGermanStandard(subTotal + tax)}{" "}
                                {currencyFormatter(product.currency)}
                              </Typography>
                            </Box>
                          </Stack>
                        );
                      })}
                  </div>
                  <MDBRow className="my-4">
                    <MDBCol md="12" className="text-success">
                      <p className="lead fw-bold mb-0">
                        {germanPriceFormat(order.total_price)}{" "}
                        {order.currency || "TRY"}
                      </p>
                    </MDBCol>
                  </MDBRow>

                  <p className="lead fw-bold mb-4 pb-2 text-success">
                    Sipariş Durumu
                  </p>
                  <MDBRow>
                    <MDBCol lg="12">
                      <div className="horizontal-timeline">
                        <ul className="list-inline items d-flex justify-content-between">
                          {statusSteps?.map((status, index) => (
                            <li
                              key={status}
                              className="list-inline-item items-list"
                              style={{ flex: 1 }}
                            >
                              <p
                                className={`py-1 px-2 rounded text-white ${
                                  index <= currentStatusIndex
                                    ? "bg-success"
                                    : "bg-secondary"
                                }`}
                                style={{
                                  marginRight:
                                    index === statusSteps.length - 1
                                      ? "-8px"
                                      : "",
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {/* CONVERT TURKISH */}
                                {status === "PENDING"
                                  ? "BEKLENİYOR"
                                  : status === "SHIPPED"
                                  ? "KARGODA"
                                  : "TESLİM EDİLDİ"}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <p className="mt-4 pt-2 mb-0">
                    Yardıma mı ihtiyacınız var ? {/* MAIL */}
                    <a
                      href="mailto:destek@akorticaret-avnikorkusuz.com"
                      className="text-success"
                    >
                      E-mail
                    </a>
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </>
  );
}
