/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react"
import Box from "@mui/joy/Box"
import Avatar from "@mui/joy/Avatar"
import Chip from "@mui/joy/Chip"
import Link from "@mui/joy/Link"
import Divider from "@mui/joy/Divider"
import IconButton from "@mui/joy/IconButton"
import Typography from "@mui/joy/Typography"
import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import ListDivider from "@mui/joy/ListDivider"
import Menu from "@mui/joy/Menu"
import MenuButton from "@mui/joy/MenuButton"
import MenuItem from "@mui/joy/MenuItem"
import Dropdown from "@mui/joy/Dropdown"
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded"
import CheckRoundedIcon from "@mui/icons-material/CheckRounded"
import BlockIcon from "@mui/icons-material/Block"
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"

const listItems = [
    {
        id: "INV-1234",
        date: "Feb 3, 2023",
        status: "Refunded",
        customer: {
            initial: "O",
            name: "Olivia Ryhe",
            email: "olivia@email.com"
        }
    }
]

function RowMenu() {
    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }}>
                <MenuItem>Detay</MenuItem>
                <MenuItem>Düzenle</MenuItem>
                <Divider />
                <MenuItem color="danger">Sil</MenuItem>
               
            </Menu>
 
        </Dropdown>
    )
}

export default function CustomerList() {
    return (
    
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
            {listItems.map(listItem => (
                <List
                    key={listItem.id}
                    size="sm"
                    sx={{
                        "--ListItem-paddingX": 0
                    }}
                >
                    <ListItem
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "start"
                        }}
                    >
                        <ListItemContent
                            sx={{ display: "flex", gap: 2, alignItems: "start" }}
                        >
                         
                            <div>
                                <Typography fontWeight={600} gutterBottom>
                                    {listItem.customer.name}
                                </Typography>
                                <Typography level="body-xs" gutterBottom>
                                    {listItem.customer.email}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: 0.5,
                                        mb: 1
                                    }}
                                >
                                    <Typography level="body-xs">{listItem.date}</Typography>
                                    <Typography level="body-xs">&bull;</Typography>
                                    <Typography level="body-xs">{listItem.id}</Typography>
                                </Box>
                        
                            </div>
                        </ListItemContent>
                        <Chip
                            variant="soft"
                            size="sm"
                            startDecorator={
                                {
                                    Paid: <CheckRoundedIcon />,
                                    Refunded: <AutorenewRoundedIcon />,
                                    Cancelled: <BlockIcon />
                                }[listItem.status]
                            }
                            color={
                                {
                                    Paid: "success",
                                    Refunded: "neutral",
                                    Cancelled: "danger"
                                }[listItem.status]
                            }
                        >
                            {listItem.status}
                        </Chip>
                    </ListItem>
                    <ListDivider />
                </List>
            ))}
            <Box
                className="Pagination-mobile"
                sx={{
                    display: { xs: "flex", md: "none" },
                    alignItems: "center",
                    py: 2
                }}
            >
                <IconButton
                    aria-label="previous page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                
                </IconButton>
                <Typography level="body-sm" mx="auto">
                    Page 1 of 10
                </Typography>
                <IconButton
                    aria-label="next page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
        </Box>
    )
}
