import * as React from "react";
import OrderList from "./OrderList";
import OrderTable from "./OrderTable";

export default function Orders() {
  return (
    <React.Fragment>
      <OrderTable />
      <OrderList />
    </React.Fragment>
  );
}
