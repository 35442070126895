import React from "react";
import axios from "axios";

export const CustomerPageContext = React.createContext();

const PageProviderCustomer = ({ children }) => {
  const [page, setPage] = React.useState("Anasayfa");
  const [pages, setPages] = React.useState({
    dashboard: "Dashboard",
    products: "Products",
    orders: "Orders",
    shopping_cart: "Shopping-Cart",
    profile: "Profile",
  });
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    async function getUser() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/customer/current`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (res.status === 200) {
          setUser(res.data.customer);
          sessionStorage.setItem("discount", res.data.customer.discount);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getUser();

    return () => {};
  }, []);

  return (
    <CustomerPageContext.Provider
      value={{
        page,
        setPage,
        pages,
        user,
      }}
    >
      {children}
    </CustomerPageContext.Provider>
  );
};

export default PageProviderCustomer;
