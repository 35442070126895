import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InventoryIcon from "@mui/icons-material/Inventory";

import { closeSidebar } from "../../../Utils/utils";
import { PageContext } from "../../../Contexts/PageContext";

function Toggler({ defaultExpanded = false, renderToggle, children }) {
  const [open, setOpen] = React.useState(defaultExpanded);

  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
  const { page, setPage, pages } = React.useContext(PageContext);

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography level="title-lg">Avni Korkusuz</Typography>
      </Box>
      <Input
        size="sm"
        startDecorator={<SearchRoundedIcon />}
        placeholder="Ara"
      />
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              selected={page === pages.dashboard}
              onClick={() => {
                setPage(pages.dashboard);
                window.location.hash = pages.dashboard;
              }}
            >
              <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Anasayfa</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={page === pages.orders}
              onClick={() => {
                setPage(pages.orders);
                window.location.hash = pages.orders;
              }}
            >
              <ShoppingCartRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Siparişler</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <InventoryIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Ürünler</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    selected={page === pages.products}
                    onClick={() => {
                      setPage(pages.products);
                      window.location.hash = pages.products;
                    }}
                  >
                    Ürün Listesi
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={page === pages.category}
                    onClick={() => {
                      setPage(pages.category);
                      window.location.hash = pages.category;
                    }}
                  >
                    Ürün Kategori Listesi
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={page === pages.campaning}
              onClick={() => {
                setPage(pages.campaning);
                window.location.hash = pages.campaning;
              }}
            >
           <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Kampanyalar</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={page === pages.customers}
              onClick={() => {
                setPage(pages.customers);
                window.location.hash = pages.customers;
              }}
            >
              <GroupRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Müşteriler</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <GroupRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Kullanıcılar</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    selected={page === pages.users.profile}
                    onClick={() => {
                      setPage(pages.users.profile);
                      window.location.hash = pages.users.profile;
                    }}
                  >
                    Benim Profilim
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={page === pages.users.create}
                    onClick={() => {
                      setPage(pages.users.create);
                      window.location.hash = pages.users.create;
                    }}
                  >
                    Yeni Kullanıcı Oluştur
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={page === pages.users.list}
                    onClick={() => {
                      setPage(pages.users.list);
                      window.location.hash = pages.users.list;
                    }}
                  >
                    Kullanıcı Listesi
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
        </List>

        <List
          size="sm"
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            "--List-gap": "8px",
            mb: 2,
          }}
        >
          {/* <ListItem>
            <ListItemButton>
              <SettingsRoundedIcon />
              Ayarlar
            </ListItemButton>
          </ListItem> */}
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar variant="outlined" size="sm" src="" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">Avni Korkusuz</Typography>
          <Typography level="body-xs">info@avnikorkusuz.com</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral" onClick={() => {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("id");
          window.location.href = "/login";
        }}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}
