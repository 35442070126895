import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import { ArrowRight as ArrowRightIcon } from "@phosphor-icons/react/dist/ssr/ArrowRight";
import { Typography } from "@mui/material";
import Table from '@mui/joy/Table';
import { PageContext } from "../../../../Contexts/PageContext";

const statusMap = {
  pending: { label: "Pending", color: "warning" },
  delivered: { label: "Delivered", color: "success" },
  refunded: { label: "Refunded", color: "error" },
};

const humanReadebleDate = (date) => {
  return new Date(date).toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
}

const translateTR = (status) => {
  switch (status) {
    case "PENDING":
      return "BEKLEMEDE"
    case "DELIVERED":
      return "TESLİM EDİLDİ"
    case "SHIPPED":
      return "KARGOYA VERİLDİ"
  }
}

export function LatestOrders({ orders = [], sx }) {
  const { pages, setPage } = React.useContext(PageContext)
  return (
    <Card variant="solid" color="neutral" invertedColors>
      <Typography variant="h6">Bekleyen Siparişler</Typography>
      <Divider />
      <Box sx={{ overflowX: "auto" }}>
        <Table hoverRow>
          <thead>
            <tr>
              <th>Sipariş Numarası</th>
              <th>Müşteri</th>
              <th>Tarih</th>
              <th>Durum</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order) => {
              console.log(order)
              const { label, color } = statusMap[order.status] ?? {
                label: "Unknown",
                color: "default",
              };

              return (
                <tr key={order._id}>
                  <td>{order.order_code}</td>
                  <td>{order.customer.company_name}</td>
                  <td>{humanReadebleDate(order.order_date)} </td>
                  <td>{translateTR(order.order_status)}</td>

                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={<ArrowRightIcon fontSize="var(--icon-fontSize-md)" />}
          size="small"
          variant="text"
          onClick={() => {
            window.location.href = "/admin/dashboard#" + pages.orders
            setPage(pages.orders)
          }}
        >
          Tüm Siparişleri Gör
        </Button>
      </CardActions>
    </Card>
  );
}
