import * as React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Sheet, Table } from "@mui/joy";
import { PageContext } from "../../../../Contexts/PageContext";
import axios from "axios";

export default function UsersList() {
  const { pages, setPage } = React.useContext(PageContext);
  const [users, setUsers] = React.useState([]);

  async function fetchUsers() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/account/all`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setUsers(res.data.accounts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box
        sx={{
          position: "sticky",
          top: { sm: -100, md: -110 },
          bgcolor: "background.body",
          zIndex: 9995,
        }}
      >
       
      </Box>

      <Sheet>
        <Table
          borderAxis="bothBetween"
          color="neutral"
          size="md"
          stickyFooter
          stickyHeader
          variant="soft"
        >
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Kullanıcı Adı</th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                İsim - Soyisim
              </th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Rol
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {user.name} {user.surname}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {user.role}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </Box>
  );
}
