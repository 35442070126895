import * as React from "react";
import { Box, IconButton } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { CustomerPageContext } from "../../../Contexts/CustomerPageContext";
import { toggleSidebar } from "./utils";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header() {
  const { page, pages } = React.useContext(CustomerPageContext);

  // DICTIONARY MAPPING ENGLISH PAGE KEYS TO TURKISH NAMES
  const pageNamesInTurkish = {
    dashboard: "Hoşgeldiniz",
    orders: "Siparişler",
    products: "Ürünler",
    profile: "Hesabım",
  };

  // FUNCTION TO DETERMINE THE CURRENT PAGE'S KEY BASED ON ITS VALUE
  const findPageKeyByValue = (obj, valueToFind) => {
    // HANDLE NESTED OBJECTS
    const flattenObject = (obj, parentKey = "", result = {}) => {
      for (let [key, value] of Object.entries(obj)) {
        let newKey = `${parentKey}${key}`;
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          flattenObject(value, `${newKey}.`, result);
        } else {
          result[newKey] = value;
        }
      }
      return result;
    };

    const flattenedPages = flattenObject(obj);
    return Object.keys(flattenedPages).find(
      (key) => flattenedPages[key] === valueToFind
    );
  };

  const currentPageKey = findPageKeyByValue(pages, page);
  const turkishPageName = currentPageKey
    ? pageNamesInTurkish[currentPageKey]
    : page;

  return (
    <Box
      sx={{
        display: "flex",
        mb: 1,
        gap: 1,
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        onClick={() => toggleSidebar()}
        variant="outlined"
        color="neutral"
        size="sm"
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography level="h2" component="h1">
        {turkishPageName}
      </Typography>
    </Box>
  );
}
