import * as React from "react";
import CategoryList from "./CategoryList";
import CategoryTable from "./CategoryTable";


export default function CategoryPage() {
  return (
    <React.Fragment>
   <CategoryList/>
   <CategoryTable/>
    </React.Fragment>
  );
}
