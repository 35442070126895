import React from "react";

export const PageContext = React.createContext();

const PageProvider = ({ children }) => {
  const [page, setPage] = React.useState("Dashboard");
  const [pages, setPages] = React.useState({
    // ADD To Header index.jsx file to display the page name if you want to display the page name
    dashboard: "Dashboard",
    orders: "Orders",
    products: "Products",
    campaning: "Campaning",
    createProducts: "NewProduct",
    category: "Categories",
    createCategory: "NewCategory",
    customers: "Customers",
    createCustomer: "NewCusomer",
    users: {
      profile: "Account",
      create: "NewAccount",
      list: "Accounts",
    },
  });

  return (
    <PageContext.Provider
      value={{
        page,
        setPage,
        pages,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
