/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BlockIcon from "@mui/icons-material/Block";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderDetailsAdminModal from "../OrderDetailsModal";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/joy";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const humanReadebleDate = (date) => {
  return new Date(date).toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const translateTR = (status) => {
  switch (status) {
    case "PENDING":
      return "BEKLEMEDE";
    case "DELIVERED":
      return "TESLİM EDİLDİ";
    case "SHIPPED":
      return "KARGOYA VERİLDİ";
    case "CANCELLED":
      return "İPTAL";
    case "REFUNDED":
      return "İADE EDİLDİ";
    default:
      return "";
  }
};

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderTable() {
  const [deleteID, setDeleteID] = React.useState("");
  const [rows, setRows] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [editProduct, setEditOrder] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orders, setOrders] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [customers, setCustomers] = React.useState([]);
  const [searchOrder, setSearchOrder] = React.useState("");
  const [customerFilter, setCustomerFilter] = React.useState("");
  const [orderStatusFilter, setOrderStatusFilter] = React.useState("");
  const [openDetailModal, setOpenDetailModal] = React.useState(false);
  const [orderDetail, setOrderDetail] = React.useState({});
  const [deleting, setDeleting] = React.useState(false);
  const [deletingOrderID, setDeletingOrderID] = React.useState("");

  async function DeleteOrder() {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/orders/${deletingOrderID}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      console.log(res);
      if (res.status === 200) {
        setOrders(orders.filter((e) => e.id !== deletingOrderID));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteConfirmation(false);
    }
  }

  function RowMenu({ row }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: "plain", color: "neutral", size: "sm" },
          }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem
            startDecorator={<RemoveRedEyeIcon />}
            onClick={() => {
              setOpenDetailModal(true);
              setOrderDetail(row || []);
            }}
          >
            Görüntüle
          </MenuItem>
          <MenuItem
            startDecorator={<DeleteIcon />}
            onClick={() => {
              setDeleteConfirmation(true);
              setDeletingOrderID(row.id);
            }}
          >
            Sil
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  async function FetchOrders() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/orders/byPage`,
        {
          params: {
            page: currentPage,
            pageSize: pageSize,
            search: searchOrder === "" ? null : searchOrder,
            customer: customerFilter === "" ? null : customerFilter,
            status: orderStatusFilter === "" ? null : orderStatusFilter,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setOrders(res.data.orders);
        console.log(res.data.orders);
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    FetchOrders();
  }, []);

  function openDeleteConfirmation() {
    setDeleteConfirmation(true);
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Durum</FormLabel>
        <Select
          size="sm"
          placeholder="Sipariş Durumu"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          value={orderStatusFilter}
          onChange={(e) => setOrderStatusFilter(e.target.value)}
        >
          <Option value="">Hepsi</Option>
          <Option value="paid" disabled>
            Ödendi - Çok yakında
          </Option>
          <Option value="pending">Beklemede</Option>
          <Option value="refunded">Kargoya Verildi</Option>
          <Option value="shipped">İade</Option>
          <Option value="delivered">Teslim Edildi</Option>
          <Option value="cancelled">İptal</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Müşteriler</FormLabel>
        <Select
          size="sm"
          placeholder="Filtrele"
          value={customerFilter}
          onChange={(e) => setCustomerFilter(e.target.value)}
        >
          <Option value="">Hepsi</Option>
          {customers.map((customer) => (
            <Option key={customer.id} value={customer.id}>
              {customer.name}
            </Option>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Ara"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Gönder
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Sipariş Ara</FormLabel>
          <Input
            size="sm"
            placeholder="Ara"
            startDecorator={<SearchIcon />}
            value={searchOrder}
            onChange={(e) => setSearchOrder(e.target.value)}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 140, padding: "12px 6px" }}>Müşteri</th>
              <th style={{ width: 140, padding: "12px 6px" }}>Tarih</th>
              <th style={{ width: 240, padding: "12px 6px" }}>Durum</th>
              <th style={{ width: 160, padding: "12px 6px" }}>Toplam Fiyat</th>
              <th style={{ width: 120, padding: "12px 6px" }}>Hareketler</th>
            </tr>
          </thead>

          {/* DÜZELECEK YER */}
          <tbody>
            {orders &&
              stableSort(orders, getComparator(order, "id")).map(
                (row, index) => (
                  <tr key={index}>
                    <td style={{ width: 140 }}>
                      <Typography level="body-xs">
                        {row?.customer?.name}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {humanReadebleDate(row.order_date)}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {translateTR(row.order_status)}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {row.total_price} TRY
                      </Typography>
                    </td>
                    <td
                      sx={{
                        width: 120,
                      }}
                    >
                      <RowMenu
                        setEdit={setEdit}
                        setEditProduct={setEditOrder}
                        row={row}
                        openDeleteConfirmation={openDeleteConfirmation}
                        setDeleteID={setDeleteID}
                      />
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={currentPage <= 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Geri
        </Button>

        <Box sx={{ flex: 1 }} />
        {Array.from({ length: totalPages }, (_, page) => (
          <IconButton
            key={page + 1}
            size="sm"
            variant={Number(page + 1) ? "outlined" : "plain"}
            color={currentPage === page + 1 ? "primary" : "neutral"}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={currentPage >= totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          İleri
        </Button>
      </Box>
      <Modal
        open={openDetailModal}
        onClose={() => setOpenDetailModal(false)}
        size="lg"
      >
        <ModalDialog
          aria-labelledby="order-details-modal"
          layout="center"
          sx={{
            width: "80%",
            bgcolor: "transparent",
            boxShadow: "none",
            p: 0,
            border: "none",
          }}
        >
          <OrderDetailsAdminModal
            order={orderDetail}
            setOpenDetailModal={setOpenDetailModal}
          />
        </ModalDialog>
      </Modal>

      <Modal
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Ürün Silme
          </DialogTitle>
          <Divider />
          <DialogContent>
            Bu siparişi silmek istediğinize emin misiniz ? Bu siparişin
            silinmesi durumunda, geri almak için sayfanın admini ile iletişime
            geçmelisiniz.
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              loading={deleting}
              onClick={() => {
                DeleteOrder();
              }}
            >
              SİL
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setDeleteConfirmation(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
