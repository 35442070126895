import * as React from "react";
import { Box } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { PageContext } from "../../../Contexts/PageContext";

export default function Header() {
  const { page, pages } = React.useContext(PageContext);

  // DICTIONARY MAPPING ENGLISH PAGE KEYS TO TURKISH NAMES
  const pageNamesInTurkish = {
    dashboard: "",
    orders: "Siparişler",
    products: "Ürünler",
    campaning: "Kampanyalar",
    createCampaning: "Kampanya Oluştur",
    createProducts: "Ürün Oluştur",
    category: "Kategoriler",
    createCategory: "Kategori Oluştur",
    customers: "Müşteriler",
    createCustomer: "Müşteri Oluştur",
    // ASSUMES FLAT STRUCTURE FOR SIMPLICITY
    "users.profile": "Hesap",
    "users.create": "Hesap Oluştur",
    "users.list": "Hesaplar",
  };

  // FUNCTION TO DETERMINE THE CURRENT PAGE'S KEY BASED ON ITS VALUE
  const findPageKeyByValue = (obj, valueToFind) => {
    // HANDLE NESTED OBJECTS
    const flattenObject = (obj, parentKey = "", result = {}) => {
      for (let [key, value] of Object.entries(obj)) {
        let newKey = `${parentKey}${key}`;
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          flattenObject(value, `${newKey}.`, result);
        } else {
          result[newKey] = value;
        }
      }
      return result;
    };

    const flattenedPages = flattenObject(obj);
    return Object.keys(flattenedPages).find(
      (key) => flattenedPages[key] === valueToFind
    );
  };

  const currentPageKey = findPageKeyByValue(pages, page);
  const turkishPageName = currentPageKey
    ? pageNamesInTurkish[currentPageKey]
    : page;

  return (
    <Box
      sx={{
        display: "flex",
        mb: 1,
        gap: 1,
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Typography level="h2" component="h1">
        {turkishPageName}
      </Typography>
    </Box>
  );
}
