import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import axios from "axios";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PageContext } from "../../../../Contexts/PageContext";
import { Divider, Modal, ModalDialog, Option, Select } from "@mui/joy";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

export default function EditProduct({ product, setEdit, refetch }) {
  const [newProduct, setNewProduct] = React.useState(product);
  const [productCreated, setProductCreated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [photo, setPhoto] = React.useState(
    newProduct?.image ? newProduct?.image : ""
  );
  const [photoURL, setPhotoURL] = React.useState(
    product?.image ? `data:image/jpeg;base64,${product.image}` : ""
  );
  const [error, setError] = React.useState({ error: false, message: "" });
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    // Kategorileri getir
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/categories/all`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        );
        if (response.status === 200) {
          setCategories(response.data.categories);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []);

  async function handleEditProduct() {
    setLoading(true);
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      if (key === "category") {
        formData.append(
          "category",
          categories.find((c) => c.id === newProduct[key])
        );
        return;
      }
      formData.append(key, newProduct[key]);
    });

    if (photo) {
      setError({ error: false, message: "Lütfen bir fotoğraf ekleyiniz." });
      formData.append("image", photo);
    }

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/products/update/${product.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (res.status === 200) {
        setProductCreated(true);
        refetch();
        setLoading(false);
        setEdit(false);
      }
    } catch (error) {
      if (error.status === 400) {
        setError({ error: true, message: error.response.data.message });
      }
      setLoading(false);
    }
  }

  const handleAddPic = () => {
    document.getElementById("photoInput").click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file); // Update the photo state with the new file
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result); // Set the photoURL to the newly selected image's data URL
      };
      reader.readAsDataURL(file);
    }
  };

  React.useEffect(() => {
    // CHECK IF 'PHOTO' IS A FILE OR BLOB
    if (photo instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result);
      };
      reader.readAsDataURL(photo);

      // CLEANUP FUNCTION TO ABORT READING IF COMPONENT UNMOUNTS
      return () => {
        reader.abort();
      };
    }
  }, [photo]);

  return (
    <React.Fragment>
      <Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "1200px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          {productCreated ? (
            // ÜRÜN BAŞARIYLA EKLENDİ SAYFASI
            <></>
          ) : (
            <Card>
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
              >
                <Stack direction="column" spacing={2}>
                  <>
                    <input
                      type="file"
                      id="photoInput"
                      style={{ display: "none" }} // Kullanıcıdan gizle
                      onChange={handleChange}
                      accept="image/*"
                    />
                    <AspectRatio
                      ratio="1"
                      maxHeight={200}
                      sx={{ flex: 1, minWidth: 200, flexDirection: "column" }}
                    >
                      <Box>
                        {/* Seçilen fotoğrafı göster */}
                        {photoURL && (
                          <img src={photoURL} loading="lazy" alt="Product" />
                        )}
                      </Box>
                    </AspectRatio>
                    <Button
                      size="sm"
                      variant="contained"
                      sx={{
                        bgcolor: photo ? "green" : "primary.500",
                        "&:hover": {
                          bgcolor: photo ? "green" : "primary.600",
                        },
                      }}
                      onClick={handleAddPic}
                    >
                      {photo ? "Fotoğrafı Değiştir" : "Fotoğraf Ekle"}
                    </Button>
                  </>
                </Stack>
                <Stack spacing={4} sx={{ flexGrow: 2 }}>
                  <Stack spacing={2}>
                    <FormLabel>Ürün Adı</FormLabel>
                    <FormControl
                      sx={{
                        display: { sm: "flex-column", md: "flex-row" },
                        gap: 2,
                      }}
                    >
                      <Input
                        value={newProduct?.name}
                        size="sm"
                        placeholder="Ürün Adı"
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            name: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Ürün Açıklaması</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Ürün Açıklaması"
                        sx={{ flexGrow: 1 }}
                        value={newProduct?.description}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            description: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Stok Kodu</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Stok Kodu"
                        sx={{ flexGrow: 1 }}
                        value={newProduct?.stockCode}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            stockCode: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing={2}>
                    <FormLabel>Ürün Tipi</FormLabel>
                    <FormControl
                      sx={{
                        display: { sm: "flex-column", md: "flex-row" },
                        gap: 2,
                      }}
                    >
                      <Input
                        size="sm"
                        placeholder="Ürün Tipi"
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            type: e.target.value,
                          })
                        }
                        value={newProduct?.type}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel> Kategori Seç</FormLabel>
                      <Select
                        size="sm"
                        placeholder="Kategori Seç"
                        sx={{ flexGrow: 1 }}
                        value={newProduct.category}
                        defaultValue={"select"}
                        onChange={(e, newValue) =>
                          setNewProduct({
                            ...newProduct,
                            category_id: newValue,
                            category_name: categories.find(
                              (category) => category.id === newValue
                            ).name,
                          })
                        }
                      >
                        <Option value="select" disabled>
                          Kategori Seç
                        </Option>
                        {categories.map((category) => {
                          return (
                            <Option key={category.id} value={category.id}>
                              {category.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Model</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Model"
                        sx={{ flexGrow: 1 }}
                        value={newProduct?.brand}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            brand: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <FormControl>
                      <FormLabel>Fiyat</FormLabel>
                      <Input
                        size="sm"
                        type="number"
                        value={newProduct?.price}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            price: parseFloat(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>KDV</FormLabel>
                      <Input
                        size="sm"
                        type="number"
                        value={newProduct?.tax}
                        onChange={(e) => {
                          setNewProduct({
                            ...newProduct,
                            tax: parseFloat(e.target.value),
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl disabled>
                      <FormLabel>Adet</FormLabel>
                      <Input
                        size="sm"
                        value={newProduct?.stock}
                        type="number"
                        onChange={(e) => {
                          setNewProduct({
                            ...newProduct,
                            stock: parseInt(e.target.value),
                          });
                        }}
                        disabled={true}
                      />
                    </FormControl>

                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>İndirim</FormLabel>
                      <Input
                        value={newProduct?.discount}
                        size="sm"
                        type="number"
                        onChange={(e) => {
                          if (
                            newProduct?.price !== null &&
                            newProduct?.price === 0 &&
                            e.target.value > 0 &&
                            e.target.value < 100 &&
                            newProduct?.discountedPrice === 0
                          )
                            return;
                          setNewProduct({
                            ...newProduct,
                            discount: parseFloat(e.target.value),
                            discountedPrice:
                              newProduct?.price -
                              newProduct?.price *
                                (parseFloat(e.target.value) / 100),
                          });
                        }}
                      />
                    </FormControl>
                  </Stack>
                  {/* CURRENCY SELECT  */}
                  <FormControl>
                    <FormLabel>Para Birimi</FormLabel>
                    <Select
                      size="sm"
                      value={newProduct?.currency}
                      onChange={(e) =>
                        setNewProduct({
                          ...newProduct,
                          currency: e.target.value,
                        })
                      }
                    >
                      <Option value="TRY">₺</Option>
                      <Option value="USD">$</Option>
                      <Option value="EUR">€</Option>
                      <Option value="GBP">£</Option>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <CardOverflow
                sx={{ borderTop: "1px solid", borderColor: "divider" }}
              >
                <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                  {loading ? (
                    <Button
                      size="sm"
                      variant="outlined"
                      color="neutral"
                      loading={loading}
                    ></Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setEdit(false)}
                      >
                        İptal
                      </Button>
                      <Button
                        size="sm"
                        variant="solid"
                        onClick={handleEditProduct}
                      >
                        Kaydet
                      </Button>
                    </>
                  )}
                </CardActions>
              </CardOverflow>
            </Card>
          )}
        </Stack>

        {/* ERROR MODAL */}
        <Modal
          open={error.error}
          onClose={() => setError({ error: false, message: "" })}
        >
          <ModalDialog variant="outlined" role="alertdialog">
            <DialogTitle>
              <WarningRoundedIcon />
              HATA
            </DialogTitle>
            <Divider />
            <DialogContent>
              {(error.message && <Typography>{error.message}</Typography>) || ( // if error.message exists
                <Typography>Bir hata oluştu. Lütfen tekrar deneyin.</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="solid"
                color="danger"
                onClick={() => setError({ error: false, message: "" })}
              >
                Tamam
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </Box>
    </React.Fragment>
  );
}
