/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Add from "@mui/icons-material/Add";
import { PageContext } from "../../../../Contexts/PageContext";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import axios from "axios";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { DialogActions, DialogContent, DialogTitle } from "@mui/joy";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CategoryTable() {
  const [order, setOrder] = React.useState("asc"); // ORDER : ASC, DESC - DEFAULT : ASC - ID SORT PURPOSE
  const [category, setCategory] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchProduct, setSearchCategories] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const [deleteCategoryID, setDeleteCategoryID] = React.useState(null);

  const { setPage, pages } = React.useContext(PageContext);

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Yeni Kategori Oluştur</FormLabel>
        <Button
          size="sm"
          variant="outlined"
          color="primary"
          onClick={() => {
            setPage(pages.createCategory);
            window.location.hash = pages.createCategory;
          }}
          startDecorator={<Add />}
        >
          Kategori Ekle
        </Button>
      </FormControl>
    </React.Fragment>
  );

  async function FetchCategories() {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/categories/byPage`,
        {
          params: {
            page: currentPage,
            pageSize: pageSize,
            search: searchProduct === "" ? null : searchProduct,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        setCategories(res.data.categories);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.totalCount);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    FetchCategories();
  }, [currentPage, pageSize, searchProduct]);

  async function deleteCategory() {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/categories/${deleteCategoryID}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        FetchCategories();
        setDeleteConfirmation(false);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Sheet
        className="chAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Ara"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Kategori Ara</FormLabel>
          <Input
            size="sm"
            placeholder="Ara"
            startDecorator={<SearchIcon />}
            onChange={(e) => {
              setSearchCategories(e.target.value);
              FetchCategories();
            }}
            value={searchProduct}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="CategoryTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "33.333333%", padding: "12px 6px" }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() =>
                    setCategory(category === "asc" ? "desc" : "asc")
                  }
                  fontWeight="lg"
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    "& svg": {
                      transition: "0.2s",
                      transform:
                        category === "desc" ? "rotate(0deg)" : "rotate(180deg)",
                    },
                  }}
                >
                  Kategori ID
                </Link>
              </th>
              <th style={{ width: "33.333333%", padding: "12px 6px" }}>
                Kategori Adı
              </th>
              <th style={{ width: "33.333333%", padding: "12px 6px" }}>
                Hareketler
              </th>
            </tr>
          </thead>

          <tbody>
            {categories &&
              stableSort(categories, getComparator(order, "id")).map(
                (row, index) => (
                  <tr key={index}>
                    <td style={{ width: 100, padding: "12px 6px" }}>
                      <Typography level="body-xs">{row.id}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{row.name}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        <IconButton
                          color="danger"
                          onClick={() => {
                            setDeleteCategoryID(row.id);
                            setDeleteConfirmation(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton>
                          <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                      </Typography>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={currentPage <= 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Geri
        </Button>

        <Box sx={{ flex: 1 }} />
        {Array.from({ length: totalPages }, (_, page) => (
          <IconButton
            key={page + 1}
            size="sm"
            variant={Number(page + 1) ? "outlined" : "plain"}
            color={currentPage === page + 1 ? "primary" : "neutral"}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={currentPage >= totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          İleri
        </Button>
      </Box>
      <Modal
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Ürün Silme
          </DialogTitle>
          <Divider />
          <DialogContent>Devam etmek istediğinize emin misiniz?</DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => deleteCategory()}
            >
              SİL
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setDeleteConfirmation(false)}
            >
              İptal
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
