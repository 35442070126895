import { Chip, IconButton, Typography } from "@mui/joy";
import axios from "axios";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React from "react";
import DiscountIcon from "@mui/icons-material/Discount";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomerPageContext } from "../../../Contexts/CustomerPageContext";

export default function Basket() {
  // STATE INITIALIZATION
  const { user } = React.useContext(CustomerPageContext);
  const [basket, setBasket] = React.useState({});
  const [products, setProducts] = React.useState([]);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [discountPrice, setDiscountPrice] = React.useState(0);
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [tax, setTax] = React.useState(0);

  React.useEffect(() => {
    // PAGE TITLE
    document.title = "Avni Korkusuz - Sepetim";

    async function fetchBasket() {
      // FETCH BASKET ITEMS FROM API
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/basket/${sessionStorage.getItem(
            "id"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (res.status === 200 && res.data.basket) {
          setBasket(res.data.basket);
          setProducts(res.data.basket.products || []);
          calculateTotal(
            res.data.basket.products,
            sessionStorage.getItem("discount")
          );
          calculateTotalQuantity(res.data.basket.products);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchBasket();
  }, []);

  function calculateTotalQuantity(products) {
    setTotalQuantity(
      products.reduce((acc, product) => acc + product.quantity, 0)
    );
  }

  async function deleteProductFromBasket(product_id) {
    // DELETE PRODUCT FROM BASKET
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/basket/remove/${product_id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        const updatedProducts = products.filter(
          (product) => product.id !== product_id
        );
        setProducts(updatedProducts);
        calculateTotal(updatedProducts, user?.discount);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function calculateTotal(products, discount = 0) {
    // CALCULATE TOTAL PRICE OF PRODUCTS BEFORE DISCOUNT
    const totalBeforeDiscount = products.reduce((acc, product) => {
      return acc + product.total_price * product.quantity;
    }, 0); // INITIAL VALUE OF ACC IS 0

    // CALCULATE TAX
    const taxAmount = products.reduce((acc, product) => {
      return acc + (product.sub_total * product.tax) / 100;
    }, 0);

    // SET TAX
    setTax(taxAmount);

    // APPLY DISCOUNT TO THE TOTAL PRICE
    const discountAmount = totalBeforeDiscount * (discount / 100);

    // SET THE TOTAL PRICE AFTER DISCOUNT
    setTotalPrice(totalBeforeDiscount);
    setDiscountPrice(discountAmount);
  }

  function currencyFormatter(currency) {
    // FORMAT CURRENCY SYMBOL
    switch (currency) {
      case "TRY":
        return "₺";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return "";
    }
  }

  async function handleCreateOrder() {
    // CREATE ORDER
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/create`,
        {
          basket_id: basket.id,
          customer_id: sessionStorage.getItem("id"),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        alert("Siparişiniz başarıyla oluşturuldu.");
        window.location.href = "/dashboard#Orders";
      }
    } catch (error) {
      console.error(error);
    }
  }

  function formatPriceToGermanStandard(totalPrice) {
    // PARSE THE PRICE TO FLOAT AND FIX IT TO TWO DECIMAL PLACES
    const price = parseFloat(totalPrice).toFixed(2);

    // CONVERT THE PRICE TO GERMAN STANDARD FORMAT
    const germanFormattedPrice = parseFloat(price).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return germanFormattedPrice;
  }

  return (
    <section className="h-100 h-custom">
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol size="12">
            <MDBCard
              className="card-registration card-registration-2"
              style={{ borderRadius: "15px" }}
            >
              <MDBCardBody className="p-0">
                <MDBRow className="g-0">
                  <MDBCol
                    lg="8"
                    style={{
                      maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {/* PRODUCTS LIST WITH SCROLL */}
                    <div className="p-5">
                      {/* PRODUCTS HEADER */}
                      <div className="d-flex justify-content-between align-items-center mb-5">
                        <MDBTypography
                          tag="h1"
                          className="fw-bold mb-0 text-black"
                        >
                          Sepetim
                        </MDBTypography>
                        <MDBTypography className="mb-0 text-muted">
                          {totalQuantity} Ürün
                        </MDBTypography>
                      </div>
                      <hr className="my-4" />

                      {/* PRODUCTS MAPPING */}
                      {products.map((product, index) => (
                        <React.Fragment key={index}>
                          <MDBRow
                            className="mb-4 d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            {/* PRODUCT DETAILS */}
                            <MDBCol md="4" lg="2" xl="2">
                              <MDBCardImage
                                src={`data:image/jpeg;base64,${product.image.Data}`}
                                fluid
                                className="rounded-3"
                              />
                            </MDBCol>
                            <MDBCol md="3" lg="3" xl="3">
                              <MDBTypography tag="h6" className="text-muted">
                                {product.stock_code}
                              </MDBTypography>
                              <MDBTypography
                                tag="span"
                                className="text-black mb-0"
                              >
                                {product.name}
                              </MDBTypography>
                            </MDBCol>
                            <MDBCol
                              md="2"
                              lg="2"
                              xl="2"
                              className="d-flex align-items-center"
                            >
                              <Typography
                                level="h6"
                                sx={{ mt: 1, fontWeight: "xl" }}
                              >
                                {product.quantity} Adet
                              </Typography>
                            </MDBCol>
                            <MDBCol md="4" lg="2" xl="3" className="text-end">
                              <Typography
                                level="h6"
                                sx={{ mt: 1, fontWeight: "xl" }}
                                endDecorator={
                                  product.discount > 0 && (
                                    <Chip
                                      component="span"
                                      size="sm"
                                      variant="soft"
                                      color="success"
                                    >
                                      <DiscountIcon /> {product.discount} %
                                    </Chip>
                                  )
                                }
                              >
                                {formatPriceToGermanStandard(
                                  product.total_price * product.quantity
                                )}{" "}
                                {currencyFormatter(product?.currency)}
                              </Typography>
                            </MDBCol>
                            <MDBCol md="1" lg="1" xl="1" className="text-end">
                              <IconButton
                                color="danger"
                                onClick={() =>
                                  deleteProductFromBasket(product.id)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </MDBCol>
                          </MDBRow>
                          <hr className="my-4" />
                        </React.Fragment>
                      ))}
                    </div>
                  </MDBCol>
                  <MDBCol lg="4" className="bg-grey">
                    {/* İskonto INFO */}
                    <div className="p-5">
                      <MDBRow className="mb-4">
                        <MDBCol size="6">
                          <MDBTypography tag="span" className="text-uppercase">
                            İskonto
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol size="6" className="text-end">
                          <MDBTypography tag="span">
                            {user?.discount} %
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    {/* TOTAL SECTION */}
                    <div className="p-5">
                      <MDBTypography
                        tag="h3"
                        className="fw-bold mb-5 mt-2 pt-1"
                      >
                        Toplam
                      </MDBTypography>
                      <hr className="my-4" />
                      <div className="d-flex justify-content-between mb-4">
                        <MDBTypography tag="span" className="text-uppercase">
                          {totalQuantity} ÜRÜN
                        </MDBTypography>
                      </div>

                      <div className="d-flex justify-content-between">
                        <MDBTypography tag="span">Ara Toplam</MDBTypography>
                        <MDBTypography tag="span">
                          {formatPriceToGermanStandard(totalPrice)}{" "}
                          {currencyFormatter("TRY")}
                        </MDBTypography>
                      </div>
                      <div className="d-flex justify-content-between">
                        <MDBTypography tag="span">İskonto</MDBTypography>
                        <MDBTypography tag="span">
                          {formatPriceToGermanStandard(discountPrice)}{" "}
                          {currencyFormatter("TRY")}
                        </MDBTypography>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <MDBTypography tag="span">
                          İndirimli Fiyat
                        </MDBTypography>
                        <MDBTypography tag="span">
                          {formatPriceToGermanStandard(
                            totalPrice - discountPrice
                          )}{" "}
                          {currencyFormatter("TRY")}
                        </MDBTypography>
                      </div>
                      <div className="d-flex justify-content-between mb-5">
                        <MDBTypography tag="span">+ KDV</MDBTypography>
                        <MDBTypography tag="span">
                          {formatPriceToGermanStandard(
                            (totalPrice - discountPrice) * 0.2
                          )}{" "}
                          {currencyFormatter("TRY")}
                        </MDBTypography>
                      </div>

                      <hr className="my-4" />
                      <div className="d-flex justify-content-between mb-5">
                        <MDBTypography tag="span" className="fw-bold">
                          Toplam
                        </MDBTypography>
                        <MDBTypography tag="span" className="fw-bold">
                          {formatPriceToGermanStandard(
                            totalPrice -
                              discountPrice +
                              (totalPrice - discountPrice) * 0.2
                          )}{" "}
                          {currencyFormatter("TRY")}
                        </MDBTypography>
                      </div>
                      <MDBBtn
                        color="dark"
                        size="lg"
                        onClick={handleCreateOrder}
                      >
                        Sipariş Ver
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
