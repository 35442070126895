import * as React from "react";
import ProductList from "./ProductList";
import ProductTable from './ProductTable';

export default function Products() {
  return (
    <React.Fragment>
      <ProductTable />
      <ProductList />
    </React.Fragment>
  );
}
