import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import axios from "axios";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PageContext } from "../../../../Contexts/PageContext";
import { Divider, Modal, ModalDialog, Option, Select } from "@mui/joy";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

export default function CreateProduct() {
  const { pages, setPage } = React.useContext(PageContext);
  const [productCreated, setProductCreated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [photo, setPhoto] = React.useState(null);
  const [photoURL, setPhotoURL] = React.useState("");
  const [error, setError] = React.useState({ error: false, message: "" });
  const [emptyFieldError, setEmptyFieldError] = React.useState({
    name: { error: false, message: "" },
    description: { error: false, message: "" },
    price: { error: false, message: "" },
    discount: { error: false, message: "" },
    discountedPrice: { error: false, message: "" },
    tax: { error: false, message: "" },
    type: { error: false, message: "" },
    brand: { error: false, message: "" },
    stock: { error: false, message: "" },
    stockCode: { error: false, message: "" },
    currency: { error: false, message: "" },
  });
  const [newProduct, setNewProduct] = React.useState({
    name: "",
    description: "",
    price: 0.0,
    discount: 0.0,
    discountedPrice: 0.0,
    tax: 0.0,
    type: "",
    brand: "",
    stock: 0,
    category_id: "",
    category_name: "",
    stockCode: "",
    currency: "TRY",
  });
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    // Kategorileri getir
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/categories/all`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        );
        if (response.status === 200) {
          setCategories(response.data.categories);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []);

  async function handleCreateProduct() {
    let isFormValid = true;
    const newEmptyFieldError = {};

    // CHECK IF PHOTO IS PROVIDED
    if (!photo) {
      setError({ error: true, message: "Lütfen bir fotoğraf ekleyin." }); // SET ERROR MESSAGE FOR PHOTO
      return; // PREVENT FURTHER EXECUTION IF NO PHOTO
    }

    Object.keys(newProduct).forEach((key) => {
      const isFieldEmpty = !newProduct[key] && newProduct[key] !== 0;
      newEmptyFieldError[key] = {
        error: isFieldEmpty,
        message: isFieldEmpty ? "Bu alan boş bırakılamaz." : "",
      };
      if (isFieldEmpty) isFormValid = false;
    });

    setEmptyFieldError(newEmptyFieldError);

    if (!isFormValid) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      formData.append(key, newProduct[key]);
    });
    // NO NEED TO CHECK PHOTO AGAIN HERE, ALREADY VALIDATED
    formData.append("image", photo);

    // LOG FORMDATA FOR DEBUGGING (IF NEEDED)
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/products/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      if (res.status === 201) {
        setProductCreated(true);
        setPage(pages.products);
        window.location.hash = pages.products;
      }
      setLoading(false);
    } catch (error) {
      if (error.status === 400) {
        setError({ error: true, message: error.response.data.message });
      }
      setLoading(false);
    }
  }

  const handleAddPic = () => {
    document.getElementById("photoInput").click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            position: "sticky",
            top: { sm: -100, md: -110 },
            bgcolor: "background.body",
            zIndex: 9995,
          }}
        >
          {/* <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="/dashboard"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="/admin/dashboard/Products"
                fontSize={12}
                fontWeight={500}
              >
                Ürünler
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Yeni Ürün Ekle
              </Typography>
            </Breadcrumbs>
          </Box> */}
          <Tabs
            defaultValue={0}
            sx={{
              bgcolor: "transparent",
            }}
          >
            <TabList
              tabFlex={1}
              size="sm"
              sx={{
                pl: { xs: 0, md: 4 },
                justifyContent: "left",
                [`&& .${tabClasses.root}`]: {
                  fontWeight: "600",
                  flex: "initial",
                  color: "text.tertiary",
                  [`&.${tabClasses.selected}`]: {
                    bgcolor: "transparent",
                    color: "text.primary",
                    "&::after": {
                      height: "2px",
                      bgcolor: "primary.500",
                    },
                  },
                },
              }}
            >
              <Tab
                sx={{ borderRadius: "6px 6px 0 0" }}
                indicatorInset
                value={0}
              >
                Ürün Ekleme
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "1200px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          {productCreated ? (
            // ÜRÜN BAŞARIYLA EKLENDİ SAYFASI
            <></>
          ) : (
            <Card>
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { md: "flex" }, my: 1 }}
              >
                <Stack direction="column" spacing={2}>
                  <>
                    <input
                      type="file"
                      id="photoInput"
                      style={{ display: "none" }}
                      onChange={handleChange}
                      accept="image/*"
                    />
                    <AspectRatio
                      ratio="1"
                      maxHeight={200}
                      sx={{ flex: 1, minWidth: 200, flexDirection: "column" }}
                    >
                      <Box>
                        {/* Seçilen fotoğrafı göster */}
                        {photoURL && (
                          <img src={photoURL} loading="lazy" alt="Product" />
                        )}
                      </Box>
                    </AspectRatio>
                    <Button
                      size="sm"
                      variant="contained"
                      sx={{
                        color: "white",
                        bgcolor: photo ? "green" : "primary.500",
                        "&:hover": {
                          bgcolor: photo ? "green" : "primary.600",
                        },
                      }}
                      onClick={handleAddPic}
                    >
                      {photo ? "Fotoğrafı Değiştir" : "Fotoğraf Ekle"}
                    </Button>
                  </>
                </Stack>
                <Stack spacing={4} sx={{ flexGrow: 2 }}>
                  <Stack spacing={2} sx={{ width: "64%" }}>
                    <FormLabel>Ürün Adı</FormLabel>
                    <FormControl
                      sx={{
                        display: { sm: "flex-column", md: "flex-row" },
                        gap: 2,
                      }}
                    >
                      <Input
                        value={newProduct.name}
                        size="sm"
                        placeholder="Ürün Adı"
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            name: e.target.value,
                          })
                        }
                        error={emptyFieldError.name.error}
                      />
                      {emptyFieldError.name.error && (
                        <Typography color="danger" fontSize={12}>
                          {emptyFieldError.name.message}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Ürün Açıklaması</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Ürün Açıklaması"
                        sx={{ flexGrow: 1 }}
                        value={newProduct.description}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            description: e.target.value,
                          })
                        }
                        error={emptyFieldError.description.error}
                      />
                      {emptyFieldError.description.error && (
                        <Typography color="danger" fontSize={12}>
                          {emptyFieldError.description.message}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Stok Kodu</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Stok Kodu"
                        sx={{ flexGrow: 1 }}
                        value={newProduct.stockCode}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            stockCode: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing={2} sx={{ width: "64%" }}>
                    <FormLabel>Ürün Tipi</FormLabel>
                    <FormControl
                      sx={{
                        display: { sm: "flex-column", md: "flex-row" },
                        gap: 2,
                      }}
                    >
                      <Input
                        size="sm"
                        placeholder="Ürün Tipi"
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            type: e.target.value,
                          })
                        }
                        value={newProduct.type}
                      />
                    </FormControl>

                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Kategori Seç</FormLabel>
                      <Select
                        size="sm"
                        placeholder="Kategori Seç"
                        sx={{ flexGrow: 1 }}
                        value={newProduct.category}
                        defaultValue={"select"}
                        onChange={(e, newValue) =>
                          setNewProduct({
                            ...newProduct,
                            category_id: newValue,
                            category_name: categories.find(
                              (category) => category.id === newValue
                            ).name,
                          })
                        }
                      >
                        <Option value="select" disabled>
                          Kategori Seç
                        </Option>
                        {categories.map((category) => {
                          return (
                            <Option key={category.id} value={category.id}>
                              {category.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Model</FormLabel>
                      <Input
                        size="sm"
                        placeholder="Model"
                        sx={{ flexGrow: 1 }}
                        value={newProduct.brand}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            brand: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing={2} sx={{ width: "64%" }}>
                    <FormControl>
                      <FormLabel>Fiyat</FormLabel>
                      <Input
                        size="sm"
                        type="number"
                        value={newProduct.price}
                        onChange={(e) =>
                          setNewProduct({
                            ...newProduct,
                            price: parseFloat(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>KDV</FormLabel>
                      <Input
                        size="sm"
                        type="number"
                        value={newProduct.tax}
                        onChange={(e) => {
                          setNewProduct({
                            ...newProduct,
                            tax: parseFloat(e.target.value),
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl disabled>
                      <FormLabel>Adet</FormLabel>
                      <Input
                        size="sm"
                        value={newProduct.stock}
                        type="number"
                        onChange={(e) => {
                          setNewProduct({
                            ...newProduct,
                            stock: parseInt(e.target.value),
                          });
                        }}
                        disabled={true}
                      />
                    </FormControl>

                    <FormControl sx={{}}>
                      <FormLabel>İndirim</FormLabel>
                      <Input
                        value={newProduct.discount}
                        size="sm"
                        type="number"
                        onChange={(e) => {
                          if (
                            newProduct.price !== null &&
                            newProduct.price === 0 &&
                            e.target.value > 0 &&
                            e.target.value < 100 &&
                            newProduct.discountedPrice === 0
                          )
                            return;
                          setNewProduct({
                            ...newProduct,
                            discount: parseFloat(e.target.value),
                            discountedPrice:
                              newProduct.price -
                              newProduct.price *
                                (parseFloat(e.target.value) / 100),
                          });
                        }}
                      />
                    </FormControl>
                    {/* CURRENCY SELECT */}
                    <FormControl>
                      <FormLabel>Para Birimi</FormLabel>
                      <Select
                        size="sm"
                        value={newProduct.currency}
                        onChange={(e, newValue) =>
                          setNewProduct({
                            ...newProduct,
                            currency: newValue,
                          })
                        }
                      >
                        <Option value="TRY">TRY</Option>
                        <Option value="USD">USD</Option>
                        <Option value="EUR">EUR</Option>
                        <Option value="GBP">GBP</Option>
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{ display: { xs: "flex", md: "none" }, my: 1 }}
              >
                <Stack direction="row" spacing={2}>
                  <Stack direction="column" spacing={1}>
                    <AspectRatio
                      ratio="1"
                      maxHeight={108}
                      sx={{ flex: 1, minWidth: 108, borderRadius: "100%" }}
                    >
                      <img
                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                        srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                        loading="lazy"
                        alt=""
                      />
                    </AspectRatio>
                    <IconButton
                      aria-label="upload new picture"
                      size="sm"
                      variant="outlined"
                      color="neutral"
                      sx={{
                        bgcolor: "background.body",
                        position: "absolute",
                        zIndex: 2,
                        borderRadius: "50%",
                        left: 85,
                        top: 180,
                        boxShadow: "sm",
                      }}
                    >
                      <EditRoundedIcon />
                    </IconButton>
                  </Stack>

                  {/* BURA NE? */}
                </Stack>
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size="sm"
                    type="email"
                    startDecorator={<EmailRoundedIcon />}
                    placeholder="email"
                    defaultValue="siriwatk@test.com"
                    sx={{ flexGrow: 1 }}
                  />
                </FormControl>
              </Stack>
              <CardOverflow
                sx={{ borderTop: "1px solid", borderColor: "divider" }}
              >
                <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                  {loading ? (
                    <Button
                      size="sm"
                      variant="outlined"
                      color="neutral"
                      loading={loading}
                    ></Button>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setPage(pages.products);
                          window.location.hash = pages.products;
                        }}
                      >
                        İptal
                      </Button>
                      <Button
                        size="sm"
                        variant="solid"
                        onClick={handleCreateProduct}
                      >
                        Kaydet
                      </Button>
                    </>
                  )}
                </CardActions>
              </CardOverflow>
            </Card>
          )}
        </Stack>

        {/* ERROR MODAL */}
        <Modal
          open={error.error}
          onClose={() => setError({ error: false, message: "" })}
        >
          <ModalDialog variant="outlined" role="alertdialog">
            <DialogTitle>
              <WarningRoundedIcon />
              HATA
            </DialogTitle>
            <Divider />
            <DialogContent>
              {(error.message && <Typography>{error.message}</Typography>) || ( // if error.message exists
                <Typography>Bir hata oluştu. Lütfen tekrar deneyin.</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="solid"
                color="danger"
                onClick={() => setError({ error: false, message: "" })}
              >
                Tamam
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </Box>
    </React.Fragment>
  );
}
